import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaTshirt } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';

import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { usePm } from '../../contexts/policialMilitar';
import { useFardamento } from '../../contexts/fardamento';
import DataTable, { IColumns } from '../../components/DataTable';

interface IFields {
  [key: string]: string | number;
}

interface IGraduacoes {
  gra_codigo: string;
  gra_nome: string;
  gra_sigla: string;
}

function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

const RelatorioPagamentoOpmItens: React.FC = () => {
  const { user } = useAuth();
  const { matPolicialMilitar } = usePm();
  const { updateIdFardamento } = useFardamento();
  const history = useHistory();
  const query = useQuery();
  const [graduacoes, setGraduacoes] = useState<IGraduacoes[]>([] || undefined);
  const [graduacoesFormatado, setGraduacoesFormatado] = useState([]);

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdFardamento(id);

      history.push(`/editarfardamento`);
    },
    [updateIdFardamento, history],
  );

  const handleClickHistorico = useCallback(
    async (id: number) => {
      await matPolicialMilitar(id);

      history.push(`/historicofardamentopm`);
    },
    [matPolicialMilitar, history],
  );

  const colunas: IColumns = [
    {
      field: 'opm_pagadora_sigla',
      text: 'Opm Pagadora',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pesquisa',
      text: 'Item',
      type: {
        name: 'text',
      },
    },

    {
      field: 'recebido_por',
      text: 'Recebido por',
      type: {
        name: 'text',
      },
    },
    {
      field: 'despachado_por',
      text: 'Despachado por',
      type: {
        name: 'text',
      },
    },
    {
      field: 'termo',
      text: 'Termo',
      type: {
        name: 'text',
      },
    },
    {
      field: 'data_entrada',
      text: 'Data',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
    },
    {
      field: 'quantidade',
      text: 'Quantidade Paga',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/itenssaidascolog/relatoriopagamentoopmitens/${user.currentOpm?.uni_codigo}`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}`,
    },
    /*  actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            handleClickEditar(uniforme.peu_codigo);
          },
        },
        {
          icon: <FaTshirt size={13} />,
          tooltip: 'Histórico Fardamento',

          getRow: (uniforme: any) => {
            handleClickHistorico(uniforme.pm_codigo);
          },
        },
      ],
    }, */
    filters: [
      /* 
      // maxWidth="calc(100vw - 40px)"
      {
        field: 'editou',
        label: 'Situação',
        options: [
          { value: 'TODOS', label: 'TODOS' },
          { value: 'PENDENTE', label: 'PENDENTE' },
          { value: 'ATUALIZADO', label: 'ATUALIZADO' },
        ],
        defaultOption: query.get('peu_editou') ? query.get('peu_editou') : '',
      }, */
    ],
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['pm_numero', 'pm_codigo', 'gra_nome', 'editou'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
    exportCsv: {
      visible: true,
      label: 'Baixar planilha',

      columns: [
        { field: 'opm_pagadora_sigla', title: 'Unidade Pagadora' },
        { field: 'opm_pm', title: 'Unidade do Policial' },
        { field: 'pesquisa', title: 'Peça' },
        { field: 'recebido_por', title: 'Recebido Por' },
        { field: 'despachado_por', title: 'Despachado Por' },
        { field: 'url_sga', title: 'Link Documento' },
        { field: 'quantidade', title: 'Quantidade Paga' },
      ],
      filename: `Relatorio-Pagamento-Itens-${user.currentOpm?.uni_sigla}`,
    },
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
    };
    loadCounts();
  }, [user]);

  return (
    <>
      <TituloPagina
        title={`Relatório de Pagamentos - ${user.currentOpm?.uni_sigla}`}
      />
      <BoxContent>
        <Box maxWidth="calc(100vw - 50px)">
          <DataTable columns={colunas} options={options} />
        </Box>
      </BoxContent>
    </>
  );
};

export default RelatorioPagamentoOpmItens;
