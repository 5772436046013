// ListarEstoque
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import {
  VStack,
  SimpleGrid,
  useToast,
  Stack,
  HStack,
  Flex,
  Button,
} from '@chakra-ui/react';
import api from '../../services/api';

import BoxContent from '../../components/BoxContent';
import { useItem } from '../../contexts/item';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useFardamento } from '../../contexts/fardamento';
import DataTable, { IColumns } from '../../components/DataTable';

interface IFields {
  [key: string]: string | number;
}

interface IGraduacoes {
  gra_codigo: string;
  gra_nome: string;
  gra_sigla: string;
}

function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

const ListarEstoqueHistoricoOpm: React.FC = () => {
  const { user } = useAuth();
  const { updateIdFardamento } = useFardamento();
  const { idItem } = useItem();
  const [item, setItem] = useState<any>();
  const history = useHistory();
  const query = useQuery();
  const [graduacoes, setGraduacoes] = useState<IGraduacoes[]>([] || undefined);
  const [graduacoesFormatado, setGraduacoesFormatado] = useState([]);

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdFardamento(id);

      history.push(`/editarfardamento`);
    },
    [updateIdFardamento, history],
  );

  const handleSair = (): void => {
    history.push('/listarestoqueopm');
  };

  const colunas: IColumns = [
    {
      field: 'termo',
      text: 'Termo',
      type: {
        name: 'text',
      },
    },
    {
      field: 'despachado_por',
      text: 'Despachado Por',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
    },
    {
      field: 'recebido_por',
      text: 'Recebido_por',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
    },
    {
      field: 'tipo',
      text: 'Tipo de Entrada',
      type: {
        name: 'text',
      },
    },
    {
      field: 'quantidade',
      text: 'Quantidade',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/itenssaidascolog/estoquehistoricoopm`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `id_item=${idItem}&opm=${user.currentOpm?.uni_codigo}`,
    },
    /*  actions: {
      headerText: 'Detalhes',
      items: [
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            handleClickEditar(uniforme.id_entrada_colog);
          },
        },
      ],
    }, */
    filters: [
      // maxWidth="calc(100vw - 40px)"
    ],
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['nota_fiscal'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - COLOG') {
        history.push('/home');
      }
      const categorias = async (): Promise<any> => {
        const listaItens = await api.get(`/itens/${idItem}`);
        setItem(listaItens.data);
      };

      categorias();
    };
    loadCounts();
  }, [user]);

  return (
    <>
      {item && (
        <>
          <TituloPagina
            title={`Historico de Entrada de Estoque do item: ${item.nome}  ${
              item.sexo || ''
            }  ${item.tamanho} ${item.tipo_batalhao || ''} ${
              item.modalidade_coturno || ''
            } ${item.lateralidade_corporal || ''} `}
          />
          <BoxContent>
            <Box maxWidth="calc(100vw - 50px)">
              <DataTable columns={colunas} options={options} />
              <Flex mt="8" justify="center">
                <HStack spacing="4">
                  <Button as="a" colorScheme="red" onClick={handleSair}>
                    Voltar
                  </Button>
                </HStack>
              </Flex>
            </Box>
          </BoxContent>
        </>
      )}
    </>
  );
};

export default ListarEstoqueHistoricoOpm;
