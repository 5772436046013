import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';

import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useFardamento } from '../../contexts/fardamento';
import DataTable, { IColumns } from '../../components/DataTable';

interface IFields {
  [key: string]: string | number;
}

interface IGraduacoes {
  gra_codigo: string;
  gra_nome: string;
  gra_sigla: string;
}
/**
 * Aqui é um comentario para demonstraçao de deploy da aplicação
 *
 */

function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

const RelatorioQuantitativo: React.FC = () => {
  const { user } = useAuth();
  const { updateIdFardamento } = useFardamento();
  const history = useHistory();
  const query = useQuery();
  const [graduacoes, setGraduacoes] = useState<IGraduacoes[]>([] || undefined);
  const [graduacoesFormatado, setGraduacoesFormatado] = useState([]);

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdFardamento(id);

      history.push(`/editarfardamento`);
    },
    [updateIdFardamento, history],
  );

  const colunas: IColumns = [
    {
      field: 'opm_nm',
      text: 'Opm',
      type: {
        name: 'text',
      },
    },
    {
      field: 'opm_superior',
      text: 'Opm-Pai',
      type: {
        name: 'text',
      },
    },
    {
      field: 'peca',
      text: 'Peça',
      type: {
        name: 'text',
      },
    },
    {
      field: 'tamanho',
      text: 'Tamanho/Lateralidade ',
      type: {
        name: 'text',
      },
    },
    {
      field: 'quantidade',
      text: 'Quantidade',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/policiais/uniformes/relatorioquantitativo2/${user.currentOpm?.uni_codigo}`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}`,
    },
    /*  actions: {
      headerText: 'Editar',
      items: [
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            console.log(
              `Aqui é a unidade ${uniforme.opm_nm} e selecionou ${uniforme.tamanho} e tb selecionou ${uniforme.peca}`,
            );
          },
        },
      ],
    }, */
    filters: [
      // maxWidth="calc(100vw - 40px)"
      /* {
        field: 'editou',
        label: 'Situação',
        options: [
          { value: 'TODOS', label: 'TODOS' },
          { value: 'PENDENTE', label: 'PENDENTE' },
          { value: 'ATUALIZADO', label: 'ATUALIZADO' },
        ],
        defaultOption: query.get('peu_editou') ? query.get('peu_editou') : '',
      }, */
    ],
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['pm_numero', 'pm_codigo', 'gra_nome', 'editou'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
    exportCsv: {
      visible: true,
      label: 'Baixar planilha',
      /* headers: [
        'Id Virtual',
        'Ordem Prioridade',
        'Opm',
        'Cod',
        'Cod',
        'Cod',
        'Opm Pai',
        'Peça',
        'Tamanho',
        'Quantidade',
      ], */
      columns: [
        { field: 'opm_nm', title: 'Opm' },
        { field: 'peca', title: 'Peça' },
        { field: 'tamanho', title: 'Tamanho' },
        { field: 'quantidade', title: 'Quantidade' },
      ],
      filename: `resumo-quantitativo-${user.currentOpm?.uni_sigla}`,
    },
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
    };
    loadCounts();
  }, [user]);

  return (
    <>
      <TituloPagina title="Relatório Quantitativo" />
      <BoxContent>
        <Box maxWidth="calc(100vw - 50px)">
          <DataTable columns={colunas} options={options} />
        </Box>
      </BoxContent>
    </>
  );
};

export default RelatorioQuantitativo;
