import React, { useEffect, useState } from 'react';
import api from 'services/api';

import { useHistory } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import DashPanel from './DashPanel';
import BoxContent from '../../components/BoxContent';
import HomeColog from '../HomeColog';
import EditarFardamentoUsuario from '../EditarFardamentoUsuario';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

interface ICounts {
  total: number;
  assinaturas: number;
  validacoes: number;
}

interface IDataDashboard {
  codigoopm: number;
  editou: string;
  total: string;
}

const Home: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [fardamentos, setFardamentos] = useState<IDataDashboard[]>([]);

  const dataValidacoes = {
    labels: ['Concluídas', 'Restantes'],
    datasets: [
      {
        label: 'Validações',
        data: [
          fardamentos.reduce((valorAtual, objeto) => {
            return valorAtual + Number(objeto.editou);
          }, 0) || 0,
          fardamentos.reduce((valorAtual, objeto) => {
            return valorAtual + Number(objeto.total);
          }, 0) -
            fardamentos.reduce((valorAtual, objeto) => {
              return valorAtual + Number(objeto.editou);
            }, 0),
        ],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      try {
        const { data } = await api.get(
          `/policiais/uniformes/dashboardopm/${user.currentOpm?.uni_codigo}?&subunidades=${user.verSubunidade}`,
        );
        if (user.currentPerfil === 'SISFARD - BCG') {
          history.push('/edituser');
        }
        if (user.currentPerfil === 'SISFARD - OPM') {
          history.push('/home');
        }
        if (user.currentPerfil === 'SISFARD - COLOG') {
          history.push('/homecolog');
        }
        setFardamentos(data);
        console.log(data);
      } catch (error) {
        console.log('Ocorreu um erro');
      }
    };
    loadCounts();
  }, [user]);
  return (
    <>
      {fardamentos && user.currentPerfil === 'SISFARD - OPM' && (
        <>
          <TituloPagina title="Painel Principal" />
          <BoxContent>
            <Flex direction="row" justifyContent="space-around" wrap="wrap">
              <DashPanel
                color="#67C58D"
                data={dataValidacoes}
                title="Fardamentos Atualizados"
                opm={user.currentOpm?.uni_sigla}
                onClick={() => history.push('/validacoes?email=VALIDADO')}
              />
            </Flex>
          </BoxContent>
        </>
      )}
    </>
  );
};

export default Home;
