/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaSave, FaSearch, FaTimes } from 'react-icons/fa';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { format, compareAsc, parseISO } from 'date-fns'

import {
  Center,
  Button,
  Flex,
  Table as TableChakra,
  Thead,
  Tbody,
  Tfoot,
  Tooltip,
  HStack,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  TableCaption,
  useToast,
  FormErrorMessage,
  FormControl,
  Box,
  useDisclosure,
  Modal,
  Container,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { FaPlus } from 'react-icons/fa';
import FormGroup from 'components/form/FormGroup';
import FormInput from 'components/form/FormInput';
import { FiTrash2 } from 'react-icons/fi';
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'debounce-promise';
import { useForm } from 'react-hook-form';
import { ValueType } from 'react-select';
import { convertCompilerOptionsFromJson } from 'typescript';
import { es } from 'date-fns/locale';
import { values } from 'lodash';
import { BeatLoader } from 'react-spinners';
import { Select } from '../../components/form/Select';
import { formatDate } from '../../utils/formataData'
import { Table, Content } from './styles';
import AsyncSelect from '../../components/form/AsyncSelect';
import Accordion from '../../components/Accordion';
import api from '../../services/api';
import AsyncSelectSga from '../../components/form/AsyncSelectSga';
import { Select as SelectMod } from '../../components/form/SelectLote';

import BoxContent from '../../components/BoxContent';
import PanelBottomActions from '../../components/PanelBottomActions';
import ModelSav from '../../components/Modal_Sav';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

import DataTable, { IColumns } from '../../components/DataTable';


type OptionType = { label: string; value: string | number; opm: any };


interface IVetor {
  id: number;
  nome: string;
  tipo: string;
  sexo: string;
  lote: string;
  tamanho: string;
  quantidade: number;
  quantidadeDevolucao: number;
  label: string;
}



const DevolucaoPmOpm: React.FC = () => {
  const { user } = useAuth();
  

  const [selectItens, setSelectItens] = useState<OptionType[] | undefined>(
    undefined,
  );

  const history = useHistory();
  const toast = useToast();

  

  const [pecaSelecionada, setPecaSelecionada] = useState<any | undefined>(
    undefined,
  );

  const [itemSelecionado, setItemSelecionado] = useState<any | undefined>(
    undefined,
  );

  const [lote, setLote] = useState<string>('');
  const [aceitaSaida, setAceitaSaida] = useState<any | undefined>(undefined);
  const [erroItem, setErroItem] = useState(false);
  const [quantidade, setQuantidade] = useState<string>('');
  const [estoque, setEstoque] = useState<number | string | undefined>();
  const [podesair, setPodeSair] = useState<number | string | undefined>();
  const [itensCarrinho, setItensCarrinho] = useState<IVetor[]>([]);
  const [botao, setBotao] = useState(true);

  const {
    isOpen: isOpenConfirmacaoMovimentacao,
    onClose: onCloseConfirmacaoMovimentacao,
    onOpen: onOpenConfirmacaoMovimentacao,
  } = useDisclosure();

  const [pessoaSelecionada, setPessoaSelecionada] = useState<OptionType>();
  const [policialSelecionado, setPolicialSelecionado] = useState<OptionType>();
  

  const colunas: IColumns = [
    {
      field: 'data_saida',
      text: 'Data',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
    },
    {
      field: 'opmquandorecebeu',
      text: 'Unidade',
      type: {
        name: 'text',
      },
    },
    {
      field: 'despachado_por',
      text: 'Pago por',
      type: {
        name: 'text',
      },
    },
    

    {
      field: 'peca',
      text: 'Item de Fardamento',
      type: {
        name: 'text',
      },
    },
    {
      field: 'quantidade',
      text: 'Quantidade Paga',
      type: {
        name: 'text',
      },
    }, {
      field: 'status',
      text: 'Status',
      type: {
        name: 'enum',
        enum: {
          '1': 'Pendente de Assinatura',
          '2': 'Liberado',
        },
      },
    },
  ];

  const options = {
    serverData: {
      url: `/itenssaidascolog/historicopagamentopm`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `matricula=${policialSelecionado?.value}`,
    },

    filters: [
      // maxWidth="calc(100vw - 40px)"
    ],
    search: {
      searchable: true,
      label: 'Pesquisar/Filtrar',
      fields: ['nota_fiscal'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };


  const handleConfirmarSaida = async (data: any): Promise<any> => {
    setBotao(false)
    
    const policialRecebedor = {
      matricula: user.matricula,
      graduacao: user.graduacao?.gra_sigla,
      nome: user.nome,
      cpf: user.cpf,
      opm: user.opm
    }
    itensCarrinho.reduce((valorAtual, objeto) => {
      return valorAtual + Number(objeto.quantidadeDevolucao);
    }, 0)
   
    // Inserir Saida
    try {
      if (itensCarrinho.length > 0) {

        const itensCarrinhoPositivos = itensCarrinho.filter(obj=>obj.quantidadeDevolucao!==0)

        const dados = {
          opmDevolcedora: user.currentOpm?.uni_codigo,
          policialRecebedor,
          matriculaDevolvedor: policialSelecionado?.value,
          opmDevolvedor: policialSelecionado?.opm,          
          status: '1',
          devopmpm: '2',
          devolucao_colog: false,
          itens: itensCarrinhoPositivos.map((obj) => {

            return {
              id_item: obj.id,
              label: obj.label,
              quantidade: obj.quantidade,
              quantidadeDevolucao: obj.quantidadeDevolucao,
              lote: '1'
            };
          }),
        };
        
         const inserirSaidaEstoque = await api.post(`/devolucao`, dados);

        toast({
          title: 'Sucesso.',
          description: 'Devolucao de fardamento aguardando assinatura',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
         history.push('/devolucaofardamentoopmconsulta');
        
      } else {
        toast({
          title: 'Atenção',
          description: 'Usuário não informou nenhum item de fardamento',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setBotao(true)
    }


    // Gerar Documento

  }

  const handleAdicionarSaida = async (data: any): Promise<any> => {
    onOpenConfirmacaoMovimentacao();
  };

  const promiseOptionsPolicialRecebedor = useCallback(
    async (inputValue: string): Promise<OptionType[] | undefined> => {
      try {
        const response = await api.get(`pessoas?query=${inputValue}`);

        const data = response.data || [];

        const responseFormated = data.map((item: any) => {
          return {
            value: item.matricula,
            label: item.dados,
            opm: item.uni_codigo
          };
        });

        // setPessoaList(response.data);

        return responseFormated;
      } catch (error) {
        return undefined;
      }
    },
    [],
  );


  const promiseOptionsPolicial = useCallback(
    async (inputValue: string): Promise<OptionType[] | undefined> => {
      try {
        const response = await api.get(`unidades/async?query=${inputValue}`);

        const data = response.data || [];

        const responseFormated = data.unidades.map((item: any) => {
          return {
            value: item.uni_codigo,
            label: item.uni_sigla,
          };
        });

        return responseFormated;
      } catch (error) {
        return undefined;
      }
    },
    [],
  ); 

  const delayedQueryPolicialSelecionado = useCallback(
    debounce((query: string) => promiseOptionsPolicialRecebedor(query), 500),
    [promiseOptionsPolicialRecebedor],
  );

  
  const delayedQuery = useCallback(
    debounce((query: string) => promiseOptionsPolicial(query), 500),
    [promiseOptionsPolicial],
  );

 
  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - COLOG') {
        history.push('/home');
      }

    }
    load();
  }, [user]);  


  useEffect(() => {
    const estoqueOpm = async (): Promise<any> => {     
        if(policialSelecionado){
          const dadosEstoque = await api.get(
            `devolucao/estoquepm?pm=${policialSelecionado?.value}`,
            );
          const carrinhoEdit = dadosEstoque.data
          const carrinhoLoop = carrinhoEdit.map((obj:any):any => {
            return {
              id: obj.id_item,
              label: obj.pesquisa,
              quantidade: obj.quantidade,
              nome: obj.pesquisa,
              quantidadeDevolucao: 0,             
            }
          })          
          setItensCarrinho(carrinhoLoop); 
        }
    };

    estoqueOpm();
   

  }, [user,policialSelecionado]);


  const handleSair = (): void => {
    history.push('/home');
  };

  const handleBotaoConfirmar = (): any => {
    if (botao) {
      return (
        <Button
          ml="8px"
          type="button"
          size="sm"
          colorScheme="green"
          onClick={handleConfirmarSaida}
        >
          Confirmar
        </Button>
      )
    }
    return (
      <Button
        isLoading
        spinner={<BeatLoader size={8} color='white' />}
        ml="8px"
        loadingText='Enviando'
        type="button"
        size="sm"
        colorScheme="green"
      >
        Confirmar
      </Button>

    )
  }

  return (
    <>
      <>
        <TituloPagina
          title={`
            Devolucao PM para ${user.currentOpm?.uni_sigla}

            `}
        />
        <BoxContent>         
          <Box maxWidth="calc(100vw - 50px)">
            Buscar Policial que irá devolver o fardamento
            <Flex width="50%">
              <AsyncSelectSga
                placeholder="Digite..."
                value={policialSelecionado}
                isClearable
                loadOptions={(value: any) => delayedQueryPolicialSelecionado(value)}
                onChange={(option: ValueType<OptionType, any>) => {
                  const optionSelected = option as OptionType;
                  setPolicialSelecionado(optionSelected || undefined);

                  if (optionSelected) {
                    setPolicialSelecionado(optionSelected);
                  }
                }}
              />
            </Flex>
          </Box>
          { policialSelecionado && (
            <>
              <Box maxWidth="calc(100vw - 50px)" mt="2">

                <Accordion label="Selecionar Itens para Pagamento" mt="2" defaultIndex={[0]} minWidth="700px">

                  <Flex direction="row" marginTop="1rem" w="100%" flexWrap="wrap" />
                  
                  <TituloPagina title={`Estoque ${policialSelecionado.label}`} />
                  <Box
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                    maxWidth="100%"
                    css={{
                        '&::-webkit-scrollbar': {
                          width: '4px',
                          height: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                          width: '6px',
                          height: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#ccc',
                          borderRadius: '24px',
                        },
                      }}
                  >
                    <Box
                      maxWidth={{
                          sm: '280px',
                          md: '100%',
                          lg: '100%',
                          xl: '100%',
                        }}
                    >
                        
                      <Table>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Peça</th>                           
                            <th>Quantidade</th>
                            <th>Quantidade a devolver</th>                            
                          </tr>
                        </thead>
                        <tbody>
                          {itensCarrinho &&
                              itensCarrinho.map((peca, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{peca.nome}</td>     
                                  <td>{peca.quantidade}</td>
                                  <td> 
                                    <NumberInput
                                      defaultValue={0}
                                      min={0}                                      
                                      max={peca.quantidade}
                                      onChange={
                                      (e)=>{
                                        itensCarrinho[index].quantidadeDevolucao=Number(e)
                                      }
                                    }
                                    >
                                      <NumberInputField />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                  </td>

                                 
                                </tr>
                              ))}
                        </tbody>
                      </Table>
                    </Box>
                  </Box>
                 
                </Accordion>
                <Accordion label="Histórico de Recebimentos da OPM" mt="2" defaultIndex={[0]} minWidth="700px">
                  <BoxContent>
                    <Box maxWidth="calc(100vw - 50px)">
                      <DataTable columns={colunas} options={options} />
                    </Box>
                  </BoxContent>
                </Accordion>

                <Flex mt="16px" direction="row" justifyContent="center">
                  <Button onClick={handleSair} size="sm" colorScheme="red">
                    Sair
                  </Button>

                  <Button ml="8px" type="submit" size="sm" colorScheme="green" onClick={handleAdicionarSaida}>
                    Reservar
                  </Button>
                </Flex>
              </Box>
              <ModelSav
                onClose={onCloseConfirmacaoMovimentacao}
                isOpen={isOpenConfirmacaoMovimentacao}
                size="xl"
                title="Itens da Devolução"
              >
                <Container>
                  <Content style={{ textAlign: 'center', fontSize: '1.3rem' }}>
                    <b>
                      Você deseja confirmar a devolucao do policial {policialSelecionado.label} para a opm {`${user.currentOpm?.uni_sigla}`} dos seguintes itens:

                      <Table>
                        <thead>
                          <tr>
                            <th className="tableCarrinhoModal">#</th>
                            <th className="tableCarrinhoModal">Peça</th>                           
                            <th className="tableCarrinhoModal">Quantidade Estoque</th>
                            <th className="tableCarrinhoModal">Quantidade Devolucao</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itensCarrinho &&
                            itensCarrinho.map((peca, index) => (
                              <tr key={index}>
                                <td className="tableCarrinhoModal">{index + 1}</td>
                                <td className="tableCarrinhoModal">{peca.nome}</td>                                
                                <td className="tableCarrinhoModal">{peca.quantidade}</td>
                                <td className="tableCarrinhoModal">{peca.quantidadeDevolucao}</td>

                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </b>
                  </Content>
                  <PanelBottomActions>
                    <Button onClick={onCloseConfirmacaoMovimentacao} size="sm" colorScheme="red">
                      Sair
                    </Button>
                    {handleBotaoConfirmar()}
                  </PanelBottomActions>
                </Container>
              </ModelSav>
            </>
          )}
        </BoxContent>
      </>
    </>
  );
};

export default DevolucaoPmOpm;
