import React from 'react';
import { Switch } from 'react-router-dom';
import CadastrarEstoque from 'pages/CadastrarEstoque';
import PagarFardamentoPmOpm from 'pages/PagarFardamentoPmOpm';
import ListarDevolucaoPmOpm from 'pages/ListarDevolucaoPmOpm';
import RelatorioPagamentoOpmItens from 'pages/RelatorioPagamentoOpmItens';
import Home from '../pages/Home';
import Route from './Route';
import SignIn from '../pages/SignIn';
import EditarFardamento from '../pages/EditarFardamento';
import CadastrarFardamento from '../pages/CadastrarFardamento';
import ListarFardamentos from '../pages/ListarFardamentos';
import ListarSaidaOpmColog from '../pages/ListarSaidaOpmColog';
import ListarSaidaOpmCologDetalhes from '../pages/ListarSaidaOpmCologDetalhes';
import ListarSaidaOpm from '../pages/ListarSaidaOpm';
import ListarSaidaOpmDetalhes from '../pages/ListarSaidaOpmDetalhes';
import ListarFardamentosPendentes from '../pages/ListarFardamentosPendentes';
import EditarFardamentoUsuario from '../pages/EditarFardamentoUsuario';
import ListarEstoque from '../pages/ListarEstoque';
import ListarEstoqueOpm from '../pages/ListarEstoqueOpm';
import ListarEntradaColog from '../pages/ListarEntradaColog';
import CadastrarFornecedor from '../pages/CadastrarFornecedor';
import CadastrarItem from '../pages/CadastrarItem';
import ListarItens from '../pages/ListarItens';
import ListarFornecedor from '../pages/ListarFornecedor';
import HomeColog from '../pages/HomeColog';
import ShowItem from '../pages/ShowItem';
import ShowFornecedor from '../pages/ShowFornecedor';
import ListarEstoqueHistorico from '../pages/ListarEstoqueHistorico';
import ListarEstoqueHistoricoOpm from '../pages/ListarEstoqueHistoricoOpm';
import ListarEstoqueHistoricoItemEntradaColog from '../pages/ListarEstoqueHistoricoItemEntradaColog';
import EditarFornecedor from '../pages/EditarFornecedor';
import EditarItem from '../pages/EditarItem';
import RelatorioQuantitativo from '../pages/RelatorioQuantitativo';
import RelatorioQuantitativo2 from '../pages/RelatorioQuantitativo2';
import RelatorioQuantitativoAlunos from '../pages/RelatorioQuantitativoAlunos';
import PagarFardamentoPm from '../pages/PagarFardamentoPm';
import PagarFardamentoOpm from '../pages/PagarFardamentoOpm';
import ListasDocumentosParaAssinar from '../pages/ListasDocumentosParaAssinar';
import FardamentosRecebidosOpm from '../pages/FardamentosRecebidosOpm';
import DocumentosPendentesColog from '../pages/DocumentosPendentesColog';
import DocumentosAssinadosColog from '../pages/DocumentosAssinadosColog';
import DocumentosPendentesOpm from '../pages/DocumentosPendentesOpm';
import DocumentosAssinadosOpm from '../pages/DocumentosAssinadosOpm';
import FardamentosRecebidosPm from '../pages/FardamentosRecebidosPm';
import DocumentosAssinadosVisaoCologOpm from '../pages/DocumentosAssinadosVisaoCologOpm';
import HistoricoFardamentoPm from '../pages/HistoricoFardamentoPm';
import EditarPagamentoOpm from '../pages/EditarPagamentoOpm';
import EditarPagamentoPm from '../pages/EditarPagamentoPm';
import ListarDevolucaoFardamentoOpm from '../pages/ListarDevolucaoPagamentoOpm';
import DevolucaoPagamentoOpm from '../pages/DevolucaoPagamentoOpm';
import ListarDevolucaoFardamentoOpmConsulta from '../pages/ListarDevolucaoPagamentoOpmConsulta';
import ListarDevolucaoFardamentoOpmConsultaNivelOpm from '../pages/ListarDevolucaoPagamentoOpmConsultaNivelOpm';
import DevolucaoOpmColog from '../pages/DevolucaoOpmColog';
import DevolucaoPmOpm from '../pages/DevolucaoPmOpm';
import DevolucaoPmColog from '../pages/DevolucaoPmColog';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/home" component={Home} isPrivate />

      <Route path="/edituser" component={EditarFardamentoUsuario} isPrivate />

      <Route
        path="/cadastrarpendentes"
        component={CadastrarFardamento}
        isPrivate
      />

      <Route path="/cadastrarestoque" component={CadastrarEstoque} isPrivate />

      <Route
        path="/cadastrarfornecedor"
        component={CadastrarFornecedor}
        isPrivate
      />

      <Route path="/cadastraritem" component={CadastrarItem} isPrivate />

      <Route
        path="/listarfardamentos"
        component={ListarFardamentos}
        isPrivate
      />

      <Route
        path="/listarfardamentospendentes"
        component={ListarFardamentosPendentes}
        isPrivate
      />

      <Route
        path="/fardamentosrecebidospm"
        component={FardamentosRecebidosPm}
        isPrivate
      />

      <Route path="/homecolog" component={HomeColog} isPrivate />

      <Route path="/listarestoque" component={ListarEstoque} isPrivate />
      <Route path="/listarestoqueopm" component={ListarEstoqueOpm} isPrivate />

      <Route
        path="/pagarfardamentoopm"
        component={PagarFardamentoPm}
        isPrivate
      />

      <Route
        path="/pagarfardamentopmopm"
        component={PagarFardamentoPmOpm}
        isPrivate
      />

      <Route path="/listaritens" component={ListarItens} isPrivate />

      <Route
        path="/relatorioquantitativo"
        component={RelatorioQuantitativo}
        isPrivate
      />
      <Route
        path="/relatorioquantitativoalunos"
        component={RelatorioQuantitativoAlunos}
        isPrivate
      />
      <Route
        path="/relatorioquantitativo2"
        component={RelatorioQuantitativo2}
        isPrivate
      />

      <Route path="/showitem" component={ShowItem} isPrivate />

      <Route path="/editarfornecedor" component={EditarFornecedor} isPrivate />

      <Route path="/editaritem" component={EditarItem} isPrivate />

      <Route path="/showfornecedor" component={ShowFornecedor} isPrivate />

      <Route
        path="/listarfornecedores"
        component={ListarFornecedor}
        isPrivate
      />

      <Route
        path="/listarentradacolog"
        component={ListarEntradaColog}
        isPrivate
      />

      <Route
        path="/listarsaidaopmcolog"
        component={ListarSaidaOpmColog}
        isPrivate
      />
      <Route
        path="/listarsaidaopmcologdetalhes"
        component={ListarSaidaOpmCologDetalhes}
        isPrivate
      />
      <Route path="/listarsaidaopm" component={ListarSaidaOpm} isPrivate />
      <Route
        path="/listarsaidaopmdetalhes"
        component={ListarSaidaOpmDetalhes}
        isPrivate
      />
      <Route
        path="/listardevolucaopmopm"
        component={ListarDevolucaoPmOpm}
        isPrivate
      />

      <Route
        path="/listarestoquehistorico"
        component={ListarEstoqueHistorico}
        isPrivate
      />

      <Route
        path="/listarestoquehistoricoopm"
        component={ListarEstoqueHistoricoOpm}
        isPrivate
      />

      <Route
        path="/listaritensentradacologhistorico"
        component={ListarEstoqueHistoricoItemEntradaColog}
        isPrivate
      />

      <Route path="/editarfardamento/" component={EditarFardamento} isPrivate />

      <Route
        path="/listasdocumentosparaassinar"
        component={ListasDocumentosParaAssinar}
        isPrivate
      />

      <Route
        path="/fardamentosrecebidosopm"
        component={FardamentosRecebidosOpm}
        isPrivate
      />

      <Route
        path="/historicofardamentopm"
        component={HistoricoFardamentoPm}
        isPrivate
      />

      <Route
        path="/documentospendentescolog"
        component={DocumentosPendentesColog}
        isPrivate
      />

      <Route
        path="/documentosassinadoscolog"
        component={DocumentosAssinadosColog}
        isPrivate
      />

      <Route
        path="/documentospendentesopm"
        component={DocumentosPendentesOpm}
        isPrivate
      />

      <Route
        path="/documentosassinadosopm"
        component={DocumentosAssinadosOpm}
        isPrivate
      />

      <Route
        path="/documentosassinadosvisaocologopm"
        component={DocumentosAssinadosVisaoCologOpm}
        isPrivate
      />

      <Route
        path="/pagarfardamentoparaopm"
        component={PagarFardamentoOpm}
        isPrivate
      />

      <Route
        path="/editarpagamentoopm"
        component={EditarPagamentoOpm}
        isPrivate
      />

      <Route
        path="/editarpagamentopm"
        component={EditarPagamentoPm}
        isPrivate
      />

      <Route
        path="/devolucaoopmcolog"
        component={DevolucaoOpmColog}
        isPrivate
      />
      <Route path="/devolucaopmopm" component={DevolucaoPmOpm} isPrivate />
      <Route path="/devolucaopmcolog" component={DevolucaoPmColog} isPrivate />

      <Route
        path="/devolucaofardamentoopmconsulta"
        component={ListarDevolucaoFardamentoOpmConsulta}
        isPrivate
      />
      <Route
        path="/relatoriopahamentoopmitens"
        component={RelatorioPagamentoOpmItens}
        isPrivate
      />

      <Route
        path="/consultadevolucaoopm"
        component={ListarDevolucaoFardamentoOpmConsultaNivelOpm}
        isPrivate
      />

      <Route
        path="/fichadevolucaofardamentoopm"
        component={DevolucaoPagamentoOpm}
        isPrivate
      />

      <Route />
    </Switch>
  );
};

export default Routes;
