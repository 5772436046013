import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaRegFilePdf, FaSearch, FaTrash } from 'react-icons/fa';
import { Box, Button, Container, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import ModalAssinatura from 'components/ModalAssinatura';
import api from '../../services/api';
import BoxContent from '../../components/BoxContent';
import { useDocumento } from '../../contexts/documento';
import { Table, Content, PdfLink } from './styles';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import DataTable, { IColumns } from '../../components/DataTable';
import PanelBottomActions from '../../components/PanelBottomActions';
import ModelSav from '../../components/Modal_Sav';
import Accordion from '../../components/Accordion';

interface IVetor {
  itens: string;
  sexo: string;
  tamanho: string;
  url_sga: string;
  modalidade_coturno: string;
  tipo_batalhao: string;
  lote: string;
  quantidade: number;
}

const DocumentosPendentesColog: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { updateIdDocumento } = useDocumento();
  const [itensSaidaColog, setItensSaidaColog] = useState<IVetor[]>([]);

  const {
    isOpen: isOpenConfirmacaoMovimentacao,
    onClose: onCloseConfirmacaoMovimentacao,
    onOpen: onOpenConfirmacaoMovimentacao,
  } = useDisclosure();

  const {
    isOpen: isOpenModalAssinatura,
    onClose: onCloseModalAssinatura,
    onOpen: onOpenModalAssinatura,
  } = useDisclosure();

  const handleInformacoesDocumento = useCallback(
    async (
      id_saida_colog: number,
      url_sga: string,
      id_documento_sga: number,
      id_documentos: number,
    ) => {
      // await updateIdDocumento(id);

      try {
        const response = await api.get(
          `documentossisfard/itensdocumento2/${id_saida_colog}`,
        );
        const itens: any = response.data;
        const teste = { ...itens, url_sga };

        const itensFormatado = itens.map((obj: any) => {
          return {
            ...obj,
            cpf_interessados: user.cpf,
            matricula: user.matricula,
            url_sga,
            id_documento_sga,
            id_documento_sisfard: id_documentos,
          };
        });

        setItensSaidaColog(itensFormatado);
        // console.log(itens);
      } catch (error) {
        console.log(error);
      }

      // history.push(`/editarfornecedor`);
    },
    [history],
  );

  const handleAbrirModalAssinar = async (data: any): Promise<any> => {
    handleInformacoesDocumento(
      data.id_saida_colog,
      data.url_sga,
      data.id_documento_sga,
      data.id_documentos,
    );
    onOpenConfirmacaoMovimentacao();
  };
  const colunas: IColumns = [
    {
      field: 'uni_sigla',
      text: 'OPM do Recebedor',
      type: {
        name: 'text',
      },
    },
    {
      field: 'uni_sigla_pagadora',
      text: 'OPM Pagadora',
      type: {
        name: 'text',
      },
    },
    {
      field: 'cod_pm',
      text: 'Matrícula',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pm_nome',
      text: 'Polícial Militar',
      type: {
        name: 'text',
      },
    },
    {
      field: 'termo',
      text: 'Termo',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pago_por',
      text: 'Pago Por',
      type: {
        name: 'text',
      },
    },
    {
      field: 'data_saida',
      text: 'Data da Saída',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
    },
    {
      field: 'termo_assinado',
      text: 'Situacao',
      type: {
        name: 'enum',
        enum: {
          '0': 'Pendente de Assinatura',
          '1': 'Assinado',
        },
      },
    },
  ];

  const options = {
    serverData: {
      url: `/documentossisfard/documentosassinadosopm`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `opm_pagadora=${user.currentOpm?.uni_codigo}`,
    },
    actions: {
      headerText: 'Visualizar',
      items: [
        {
          icon: <FaSearch size={13} />,
          tooltip: 'Visualizar',

          getRow: handleAbrirModalAssinar,
        },
      ],
    },
    filters: [
      // maxWidth="calc(100vw - 40px)"
    ],
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['uni_nome'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  /* useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      console.log('Nivel de Permissão');
    };
    loadCounts();
  }, []); */

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - COLOG') {
        history.push('/home');
      }
    }
    load();
  }, [user]);

  return (
    <>
      <TituloPagina title="Receber Fardamento" />
      <BoxContent>
        <Box maxWidth="calc(100vw - 50px)">
          <DataTable columns={colunas} options={options} />
        </Box>
        {itensSaidaColog && (
          <ModelSav
            onClose={onCloseConfirmacaoMovimentacao}
            isOpen={isOpenConfirmacaoMovimentacao}
            size="xl"
            title="Itens do Pagamento"
          >
            <Container>
              <Content style={{ textAlign: 'center', fontSize: '1.3rem' }}>
                <b>
                  O seguinte termo se refere ao pagamento descrito abaixo clique
                  em visualizar termo para ter acesso ao documento assinado
                  digitalmente
                  <Table>
                    <thead>
                      <tr>
                        <th className="tableCarrinhoModal">#</th>
                        <th className="tableCarrinhoModal">Peça</th>
                        <th className="tableCarrinhoModal">Tipo</th>
                        <th className="tableCarrinhoModal">Sexo</th>
                        <th className="tableCarrinhoModal">Lote</th>
                        <th className="tableCarrinhoModal">Tamanho</th>
                        <th className="tableCarrinhoModal">Quantidade</th>
                      </tr>
                    </thead>

                    <tbody>
                      {itensSaidaColog &&
                        itensSaidaColog.map((obj, index) => (
                          <tr key={index}>
                            <td className="tableCarrinhoModal">{index + 1}</td>
                            <td className="tableCarrinhoModal">{obj.itens}</td>
                            <td className="tableCarrinhoModal">
                              {obj.tipo_batalhao || 'Único'}
                            </td>
                            <td className="tableCarrinhoModal">
                              {obj.sexo || 'U'}
                            </td>
                            <td className="tableCarrinhoModal">{obj.lote}</td>
                            <td className="tableCarrinhoModal">
                              {obj.tamanho || 'Único'}
                            </td>
                            <td className="tableCarrinhoModal">
                              {obj.quantidade}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </b>
                <PdfLink
                  href={itensSaidaColog?.[0]?.url_sga}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaRegFilePdf size={60} />
                  Visualizar Termo
                </PdfLink>
              </Content>
            </Container>
          </ModelSav>
        )}
        <ModalAssinatura
          isOpen={isOpenModalAssinatura}
          onClose={onCloseModalAssinatura}
          cargos={[]}
          size="2xl"
          data={{
            itensSaidaColog,
            cpf: user.cpf,
          }}
        />
      </BoxContent>
    </>
  );
};

export default DocumentosPendentesColog;
