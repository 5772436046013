import { Box, Flex, useMediaQuery, Center } from '@chakra-ui/react';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ExportCSV from '../ExportCSV';

interface IDataset {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

interface IDashPanelProps {
  title: string;
  color: string;
  opm: string | undefined;
  data: {
    labels: string[];
    datasets: IDataset[];
  };
  onClick?(): void;
}

const DashPanel: React.FC<IDashPanelProps> = ({
  color,
  title,
  data,
  opm,
  onClick,
}) => {
  const [maiorQue480px] = useMediaQuery('(min-width: 480px)');
  return (
    <Flex
      direction="column"
      p={{ base: '0px', sm: '0px', md: '0px', lg: '20px' }}
      alignItems="center"
      marginBottom={{ sm: '8px', md: '8px', lg: '20px' }}
      width={{ base: '50%', lg: '50%', xl: '50%', md: '100%', sm: '100%' }}
      onClick={onClick}
      cursor="pointer"
    >
      <Box
        bg={color}
        width="100%"
        minHeight="110px"
        borderRadius="4px"
        p="16px"
        flexDirection="column"
        justifyContent="space-between"
        color="#fff"
      >
        <Flex direction="row" justifyContent="space-between">
          <Box fontWeight="bold" fontSize="1.125rem">
            {title}
          </Box>
          <Flex direction="column" justifyContent="space-between">
            <Box fontWeight="bold" fontSize="1.25rem">
              {data.datasets[0].data[0]}/
              {data.datasets[0].data[1] + data.datasets[0].data[0]}
            </Box>
          </Flex>
        </Flex>
        <Box fontWeight="400" fontSize="0.95rem">
          {opm}
        </Box>
      </Box>
      {maiorQue480px && (
        <Box
          bg="#fff"
          minHeight="160px"
          mt="8px"
          borderRadius="4px"
          width="100%"
          border="1px solid #ddd"
          align="center"
          py="8px"
        >
          <Flex
            direction="column"
            width="80%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            padding="4px"
          >
            <Doughnut
              data={data}
              // options={{
              //   responsive: true,
              // }}
            />
            <Center mt="2" w="100%">
              <ExportCSV
                async
                url="https://api-fardamento.pm.ce.gov.br/policiais/uniformes/dashboardcolog/listar"
                fileName="relatorio-sisfard-pmce"
                headers={[
                  'Sequencial',
                  'Sigla',
                  'Opm Superior',
                  'Ordem Hierarquica',
                  'Identificador',
                  'Opm Grande Comando',
                  'Efetivo Total',
                  'Efetivo Atualizados',
                  'Efetivo Pendentes',
                ]}
              />
            </Center>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default DashPanel;
