import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';

import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useFardamento } from '../../contexts/fardamento';
import DataTable, { IColumns } from '../../components/DataTable';

const Cadastrar: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { updateIdFardamento } = useFardamento();

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdFardamento(id);

      history.push(`/editarfardamento`);
    },
    [updateIdFardamento, history],
  );
  const colunas: IColumns = [
    {
      field: 'pm_codigo',
      text: 'Matrícula',
      type: {
        name: 'text',
      },
    },
    {
      field: 'gra_nome',
      text: 'Post./Grad.',
      type: {
        name: 'text',
      },
    },

    {
      field: 'pm_apelido',
      text: 'Nome de Guerra',
      type: {
        name: 'text',
      },
    },

    {
      field: 'peu_cabeca',
      text: 'Cobertura',
      type: {
        name: 'text',
      },
    },

    {
      field: 'peu_blusa_interna',
      text: 'Camisa Interna',
      type: {
        name: 'text',
      },
    },
    {
      field: 'peu_calca',
      text: 'Calça',
      type: {
        name: 'text',
      },
    },
    {
      field: 'peu_sapato',
      text: 'Coturno',
      type: {
        name: 'text',
      },
    },
    {
      field: 'peu_gandola',
      text: 'Gandola',
      type: {
        name: 'text',
      },
    },
    {
      field: 'peu_coldre',
      text: 'Coldre',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/policiais/uniformes/opm/pendentes/${user.currentOpm?.uni_codigo}`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      // params: `opm=${user.currentOpm?.uni_codigo}&subunidades=${user.verSubunidade}`,
    },
    actions: {
      headerText: 'Editar',
      items: [
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            handleClickEditar(uniforme.peu_codigo);
          },
        },
      ],
    },
    filters: [],
  };

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - COLOG') {
        history.push('/home');
      }
    }
    load();
  }, [user]);

  return (
    <>
      <TituloPagina title="Relação de Policiais Pendentes" />
      <BoxContent>
        <Box maxWidth="calc(100vw - 50px)">
          <DataTable columns={colunas} options={options} />
        </Box>
      </BoxContent>
    </>
  );
};

export default Cadastrar;
