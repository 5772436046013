// ListarEstoque
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useFornecedor } from '../../contexts/fornecedor';
import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useFardamento } from '../../contexts/fardamento';
import DataTable, { IColumns } from '../../components/DataTable';

const ListarFornecedor: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { updateIdFornecedor } = useFornecedor();

  const handleClickVisualizar = useCallback(
    async (id: number) => {
      await updateIdFornecedor(id);

      history.push(`/showfornecedor`);
    },
    [updateIdFornecedor, history],
  );

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdFornecedor(id);

      history.push(`/editarfornecedor`);
    },
    [updateIdFornecedor, history],
  );

  const colunas: IColumns = [
    {
      field: 'nome',
      text: 'Nome',
      type: {
        name: 'text',
      },
    },
    {
      field: 'endereco',
      text: 'Endereço',
      type: {
        name: 'text',
      },
    },
    {
      field: 'telefone',
      text: 'Telefone',
      type: {
        name: 'text',
      },
    },
    {
      field: 'cnpj',
      text: 'CNPJ',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/fornecedores`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      // params: `teste=teste`,
    },
    actions: {
      headerText: 'Detalhes',
      items: [
        {
          icon: <FaSearch size={13} />,
          tooltip: 'Detalhe',

          getRow: (uniforme: any) => {
            handleClickVisualizar(uniforme.id_fornecedor);
          },
        },
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Detalhe',

          getRow: (uniforme: any) => {
            handleClickEditar(uniforme.id_fornecedor);
          },
        },
      ],
    },
    filters: [
      // maxWidth="calc(100vw - 40px)"
    ],
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['nota_fiscal'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }
    };
    loadCounts();
  }, [user]);

  return (
    <>
      <TituloPagina title="Fornecedores" />
      <BoxContent>
        <Box maxWidth="calc(100vw - 50px)">
          <DataTable columns={colunas} options={options} />
        </Box>
      </BoxContent>
    </>
  );
};

export default ListarFornecedor;
