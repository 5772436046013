// ListarEstoque
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';

import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

import { useItem } from '../../contexts/item';
import DataTable, { IColumns } from '../../components/DataTableListEstoque';

interface IFields {
  [key: string]: string | number;
}

interface IGraduacoes {
  gra_codigo: string;
  gra_nome: string;
  gra_sigla: string;
}

function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

const ListarEstoque: React.FC = () => {
  const { user } = useAuth();
  const { updateIdItem } = useItem();
  const history = useHistory();
  const query = useQuery();
  const [graduacoes, setGraduacoes] = useState<IGraduacoes[]>([] || undefined);
  const [graduacoesFormatado, setGraduacoesFormatado] = useState([]);

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdItem(id);
      history.push(`/listarestoquehistoricoopm`);
    },
    [updateIdItem, history],
  );

  const colunas: IColumns = [
    {
      field: 'pesquisa',
      text: 'Item de Fardamento',
      type: {
        name: 'text',
      },
    },
    {
      field: 'quantidade',
      text: 'Quantidade',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/entradasopm/estoqueopm`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `opm=${user.currentOpm?.uni_codigo}`,
    },
    actions: {
      headerText: 'Detalhes',
      items: [
        {
          icon: <FaSearch size={13} />,
          tooltip: 'Detalhe',

          getRow: (uniforme: any) => {
            handleClickEditar(uniforme.id_item);
          },
        },
      ],
    },
    filters: [
      // maxWidth="calc(100vw - 40px)"
    ],
    search: {
      searchable: true,
      label: 'Pesquisar/Filtrar',
      fields: ['nota_fiscal'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - COLOG') {
        history.push('/home');
      }
    }
    load();
  }, [user]);

  return (
    <>
      <TituloPagina title={`Estoque Opm ${user.currentOpm?.uni_sigla}`} />
      <BoxContent>
        <Box maxWidth="calc(100vw - 50px)">
          <DataTable columns={colunas} options={options} />
        </Box>
      </BoxContent>
    </>
  );
};

export default ListarEstoque;
