/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaSave, FaSearch, FaTimes } from 'react-icons/fa';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { format, compareAsc, parseISO } from 'date-fns'

import {
  Center,
  Button,
  Flex,
  Table as TableChakra,
  Thead,
  Tbody,
  Tfoot,
  Tooltip,
  HStack,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  TableCaption,
  useToast,
  FormErrorMessage,
  FormControl,
  Box,
  useDisclosure,
  Modal,
  Container,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { FaPlus } from 'react-icons/fa';
import FormGroup from 'components/form/FormGroup';
import FormInput from 'components/form/FormInput';
import { FiTrash2 } from 'react-icons/fi';
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'debounce-promise';
import { useForm } from 'react-hook-form';
import { ValueType } from 'react-select';
import { convertCompilerOptionsFromJson } from 'typescript';
import { es } from 'date-fns/locale';
import { values } from 'lodash';
import { BeatLoader } from 'react-spinners';
import { Select } from '../../components/form/Select';
import { formatDate } from '../../utils/formataData'
import { Table, Content } from './styles';
import AsyncSelect from '../../components/form/AsyncSelect';
import Accordion from '../../components/Accordion';
import api from '../../services/api';


import AsyncSelectSga from '../../components/form/AsyncSelectSga';
import { Select as SelectMod } from '../../components/form/SelectLote';

import BoxContent from '../../components/BoxContent';
import PanelBottomActions from '../../components/PanelBottomActions';
import ModelSav from '../../components/Modal_Sav';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

import DataTable, { IColumns } from '../../components/DataTable';



type OptionType = { label: string; value: string | number; opm: any };


interface IFardamento {
  peu_codigo: string;
  pm_apelido: string;
  gra_codigo: string;
  gra_nome: string;
  peu_cabeca: string;
  peu_blusa_interna: string;
  peu_calca: string;
  data_alteracao: Date;
  peu_gandola: string;
  pm_codigo: string;
  peu_calca_sexo: string;
  peu_gandola_sexo: string;
  peu_coldre: string;
  peu_sapato: string;
  lote: string;
  editou: string;
}

interface IVetor {
  id: number;
  nome: string;
  tipo: string;
  sexo: string;
  lote: string;
  tamanho: string;
  quantidade: number;
  label: string;
}

const schema = Yup.object().shape({
  // peu_calca: Yup.string().required('Campo Obrigatório'),
  // peu_calca_sexo: Yup.string().required('Campo Obrigatório'),
  // peu_cabeca: Yup.string().required('Campo Obrigatório'),
  // peu_gandola_sexo: Yup.string().required('Campo Obrigatório'),
  // peu_blusa_interna: Yup.string().required('Campo Obrigatório'),
  // peu_gandola: Yup.string().required('Campo Obrigatório'),
  // peu_sapato: Yup.string().required('Campo Obrigatório'),
  // peu_coldre: Yup.string().required('Campo Obrigatório'),
});

const PagarFardamentoPm: React.FC = () => {
  const { user } = useAuth();
  const [fardamento, setFardamento] = useState<IFardamento | undefined>(
    undefined,
  );

  const [selectItens, setSelectItens] = useState<OptionType[] | undefined>(
    undefined,
  );

  const history = useHistory();
  const toast = useToast();

  const {
    control,
    errors,
    reset,
    handleSubmit,
    watch,
    getValues,
    register,
  } = useForm<IFardamento>({
    resolver: yupResolver(schema),
    defaultValues: {
      peu_cabeca: fardamento?.peu_cabeca,
      peu_calca: fardamento?.peu_calca,
      peu_blusa_interna: fardamento?.peu_blusa_interna,
      peu_calca_sexo: fardamento?.peu_calca_sexo,
      peu_coldre: fardamento?.peu_coldre,
      peu_gandola_sexo: fardamento?.peu_gandola_sexo,
      peu_sapato: fardamento?.peu_sapato,
      peu_gandola: fardamento?.peu_gandola,
    },
  });

  const [pecaSelecionada, setPecaSelecionada] = useState<any | undefined>(
    undefined,
  );

  const [itemSelecionado, setItemSelecionado] = useState<any | undefined>(
    undefined,
  );

  const [lote, setLote] = useState<string>('');
  const [aceitaSaida, setAceitaSaida] = useState<any | undefined>(undefined);
  const [erroItem, setErroItem] = useState(false);
  const [quantidade, setQuantidade] = useState<string>('');
  const [estoque, setEstoque] = useState<number | string | undefined>();
  const [podesair, setPodeSair] = useState<number | string | undefined>();
  const [itensCarrinho, setItensCarrinho] = useState<IVetor[]>([]);
  const [botao, setBotao] = useState(true);

  const {
    isOpen: isOpenConfirmacaoMovimentacao,
    onClose: onCloseConfirmacaoMovimentacao,
    onOpen: onOpenConfirmacaoMovimentacao,
  } = useDisclosure();

  const handleAdicionarItem = async (): Promise<void> => {

    if (pecaSelecionada && quantidade && lote && (Number(quantidade) > 0)) {
      setErroItem(false);

      if ((Number(estoque) - Number(quantidade)) < 0) {
        toast({
          title: 'Erro.',
          description: 'Estoque insuficiente',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        try {
          const response = await api.get(`itens/${pecaSelecionada.value}`);
          const item: any = response.data;
          setItensCarrinho([
            ...itensCarrinho,
            {
              id: Number(pecaSelecionada.value),
              label: pecaSelecionada.label,
              quantidade: Number(quantidade),
              nome: item.nome,
              sexo: item.sexo,
              lote,
              tamanho: item.tamanho,
              tipo: item.tipo_batalhao,
            },
          ]);
          setPecaSelecionada(0);
          setLote('')
          setQuantidade('');
          setEstoque(undefined);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      setErroItem(true);
    }
  };

  const consultaEstoque = async (data: number): Promise<any> => {

    const estoqueConsulta = await api.get(`/itens/estoque/13`);

    const numeroEstoque = estoqueConsulta.data.quantidade

    return Number(numeroEstoque)
  }

  const handleDeletarItem = (id: any): any => {

    const listItemRemovido = itensCarrinho.filter((item) => item.id !== id);

    setItensCarrinho(listItemRemovido);
    setPecaSelecionada(0);
    setQuantidade('');
    setLote('')
    setEstoque(undefined);

  };

  const [pessoaSelecionada, setPessoaSelecionada] = useState<OptionType>();
  const [policialSelecionado, setPolicialSelecionado] = useState<OptionType>();
  const [lotesDisponiveis, setLotesDisponiveis] = useState<any>(
    [],
  );

  const handleUpdate = async (data: any): Promise<void> => {
    const atualizar = await api.put(
      `/policiais/uniformes/${fardamento?.peu_codigo}`,
      data,
    );
    toast({
      title: 'Sucesso.',
      description: 'Fardamento Atualizado com sucesso',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });
    history.push('/edituser');
  };


  const colunas: IColumns = [
    {
      field: 'data_saida',
      text: 'Data',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
    },
    {
      field: 'opm_sigla',
      text: 'Unidade',
      type: {
        name: 'text',
      },
    },
    {
      field: 'despachado_por',
      text: 'Pago por',
      type: {
        name: 'text',
      },
    },
    {
      field: 'recebido_por',
      text: 'Recebido por',
      type: {
        name: 'text',
      },
    },

    {
      field: 'peca',
      text: 'Item de Fardamento',
      type: {
        name: 'text',
      },
    },
    {
      field: 'quantidade',
      text: 'Quantidade Paga',
      type: {
        name: 'text',
      },
    }, {
      field: 'status',
      text: 'Status',
      type: {
        name: 'enum',
        enum: {
          '1': 'Pendente de Assinatura',
          '2': 'Liberado',
        },
      },
    },
  ];

  const options = {
    serverData: {
      url: `/itenssaidascolog/historicopagamentoopm`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `opm=${pessoaSelecionada?.value}`,
    },

    filters: [
      // maxWidth="calc(100vw - 40px)"
    ],
    search: {
      searchable: true,
      label: 'Pesquisar/Filtrar',
      fields: ['nota_fiscal'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };


  const handleConfirmarSaida = async (data: any): Promise<any> => {
    setBotao(false)
    const policialPagador = {
      matricula: user.matricula,
      graduacao: user.graduacao?.gra_sigla,
      nome: user.nome,
      cpf: user.cpf,
      opm: user.opm
    }
    // Inserir Saida
    try {
      if (itensCarrinho.length > 0) {
        const dados = {
          opmRecebedora: pessoaSelecionada?.value,
          policialPagador,
          matriculaRecebedor: policialSelecionado?.value,
          opmRecebedor: policialSelecionado?.opm,
          data_saida: format(new Date(), 'yyyy/MM/dd'),
          status: '1',
          itens: itensCarrinho.map((obj) => {

            return {
              id_item: obj.id,
              label: obj.label,
              quantidade: obj.quantidade,
              lote: obj.lote
            };
          }),
        };

        const inserirSaidaEstoque = await api.post(`/saidacolog/estoqueopm`, dados);

        toast({
          title: 'Sucesso.',
          description: 'Reserva de fardamento realizada com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        history.push('/documentospendentescolog');
      } else {
        toast({
          title: 'Atenção',
          description: 'Usuário não informou nenhum item de fardamento',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setBotao(true)
    }


    // Gerar Documento

  }

  const handleAdicionarSaida = async (data: any): Promise<any> => {
    onOpenConfirmacaoMovimentacao();
  };

  const promiseOptionsPolicialRecebedor = useCallback(
    async (inputValue: string): Promise<OptionType[] | undefined> => {
      try {
        const response = await api.get(`pessoas?query=${inputValue}`);

        const data = response.data || [];

        const responseFormated = data.map((item: any) => {
          return {
            value: item.matricula,
            label: item.dados,
            opm: item.uni_codigo
          };
        });

        // setPessoaList(response.data);

        return responseFormated;
      } catch (error) {
        return undefined;
      }
    },
    [],
  );


  const promiseOptionsPolicial = useCallback(
    async (inputValue: string): Promise<OptionType[] | undefined> => {
      try {
        const response = await api.get(`unidades/async?query=${inputValue}`);

        const data = response.data || [];

        const responseFormated = data.unidades.map((item: any) => {
          return {
            value: item.uni_codigo,
            label: item.uni_sigla,
          };
        });

        return responseFormated;
      } catch (error) {
        return undefined;
      }
    },
    [],
  );

  const promiseItens = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`itens/pesquisa?query=${inputValue}`);

      const data = response.data || [];

      const responseFormated = data.map((item: any) => {
        return {
          value: item.id_item,
          label: item.pesquisa,
        };
      });
      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

  const delayedQueryPolicialSelecionado = useCallback(
    debounce((query: string) => promiseOptionsPolicialRecebedor(query), 500),
    [promiseOptionsPolicialRecebedor],
  );

  const delayedQueryItens = useCallback(
    debounce((query: string) => promiseItens(query), 500),
    [promiseItens],
  );

  const delayedQuery = useCallback(
    debounce((query: string) => promiseOptionsPolicial(query), 500),
    [promiseOptionsPolicial],
  );

  const carregaEstoque = async (dados: any): Promise<any> => {

    try {
      const estoqueDados = await api.get(`itens/estoque/${dados.value}`);
      const estoqueAtual = estoqueDados.data.quantidade - itensCarrinho.filter(objeto => objeto.id === Number(dados.value)).reduce((valorAtual, objeto2) => {
        return valorAtual + Number(objeto2.quantidade);
      }, 0)
      if (estoqueAtual > 0) {
        setEstoque(estoqueDados.data.quantidade - itensCarrinho.filter(objeto => objeto.id === Number(dados.value)).reduce((valorAtual, objeto2) => {
          return valorAtual + Number(objeto2.quantidade);
        }, 0));
      } else {
        setEstoque('0');
      }
    } catch (error) {
      setEstoque('0');
    }
  };

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }

    }
    load();
  }, [user]);

  useEffect(() => {
    async function load(): Promise<void> {
      const selectItensDados = await api.get(
        `itens`,
      );
      if (selectItensDados.data) {
        console.log(selectItensDados.data)
        const optionsItens = selectItensDados.data.items.map((item: any) => {
          return {
            value: item.id_item,
            label: `Nome: ${item.nome}: tamanho: ${item.tamanho || 'Único'
              } tipo: ${item.tipo_batalhao || 'Único'} `,
          };
        });
        setSelectItens(optionsItens);

      }
    }
    load();
  }, [pessoaSelecionada]);


  useEffect(() => {
    const lotes = async (): Promise<any> => {
      const listaLotes = await api.get('/entradascolog/lotes');
      console.log(listaLotes.data.items)
      const optionsLotes = listaLotes.data.map(
        (listarlote: any) => {
          return {
            value: listarlote.lote,
            label: listarlote.lote,

          };

        },
      );
      setLotesDisponiveis(optionsLotes);
    };

    lotes();

  }, [user]);


  const handleSair = (): void => {
    history.push('/home');
  };

  const handleBotaoConfirmar = (): any => {
    if (botao) {
      return (
        <Button
          ml="8px"
          type="button"
          size="sm"
          colorScheme="green"
          onClick={handleConfirmarSaida}
        >
          Confirmar
        </Button>
      )
    }
    return (
      <Button
        isLoading
        spinner={<BeatLoader size={8} color='white' />}
        ml="8px"
        loadingText='Enviando'
        type="button"
        size="sm"
        colorScheme="green"
      >
        Confirmar
      </Button>

    )
  }

  return (
    <>
      <>
        <TituloPagina
          title={`
            Pagar Fardamento OPM

            `}
        />
        <BoxContent>
          <Box maxWidth="calc(100vw - 50px)">
            Buscar Unidade
            <Flex width="50%">
              <AsyncSelectSga
                placeholder="Digite..."
                value={pessoaSelecionada}
                isClearable
                loadOptions={(value: any) => delayedQuery(value)}
                onChange={(option: ValueType<OptionType, any>) => {
                  const optionSelected = option as OptionType;
                  setPessoaSelecionada(optionSelected || undefined);

                  if (optionSelected) {
                    setPessoaSelecionada(optionSelected);
                  }
                }}
              />
            </Flex>
          </Box>
          <Box maxWidth="calc(100vw - 50px)">
            Buscar Policial que ira receber os itens de fardamento da Companhia
            <Flex width="50%">
              <AsyncSelectSga
                placeholder="Digite..."
                value={policialSelecionado}
                isClearable
                loadOptions={(value: any) => delayedQueryPolicialSelecionado(value)}
                onChange={(option: ValueType<OptionType, any>) => {
                  const optionSelected = option as OptionType;
                  setPolicialSelecionado(optionSelected || undefined);

                  if (optionSelected) {
                    setPolicialSelecionado(optionSelected);
                  }
                }}
              />
            </Flex>
          </Box>
          {pessoaSelecionada && policialSelecionado && (
            <>
              <Box maxWidth="calc(100vw - 50px)" mt="2">

                <Accordion label="Selecionar Itens para Pagamento" mt="2" defaultIndex={[0]} minWidth="700px">

                  <Flex direction="row" marginTop="1rem" w="100%" flexWrap="wrap">
                    <FormGroup name="Pesquisar Item" cols={[7, 10, 10]}>
                      <AsyncSelect
                        name="pes_codigo"
                        label="Pesquisar Policial"
                        id="pes_codigo"
                        value={pecaSelecionada}
                        loadOptions={(value: any) => delayedQueryItens(value)}
                        onChange={(value: any) => {
                          setPecaSelecionada(value)
                          carregaEstoque(value)
                        }}
                        error={erroItem ? 'Campo obrigatório' : undefined}
                      />
                    </FormGroup>
                    {/* <FormGroup name="Lote" cols={[2, 6, 12]}>
                      <FormInput
                        id="lote"
                        value={lote}
                        onChange={(e) => setLote(e.target.value)}
                        error={erroItem ? 'Campo obrigatório' : undefined}
                      />
                    </FormGroup> */}

                    <FormGroup name="Lote" cols={[1, 1, 1]}>
                      <SelectMod
                        placeholder="Selecione uma Opção"
                        name="lote"
                        value={lote}
                        options={lotesDisponiveis}
                        onChange={(e) => setLote(e.target.value)}
                        error={erroItem ? 'Campo obrigatório' : undefined}
                      />
                    </FormGroup>

                    <FormGroup name="Quantidade" cols={[2, 6, 12]}>
                      <FormInput
                        id="quantidade"
                        value={quantidade}
                        error={erroItem ? 'Campo obrigatório' : undefined}
                        onChange={(e) => setQuantidade(e.target.value)}
                      />
                    </FormGroup>
                    {estoque && (
                      <FormGroup name="Estoque" cols={[1, 5, 11]}>
                        <FormInput disabled value={estoque} />
                      </FormGroup>
                    )}
                    <FormGroup name="ㅤ" cols={[1, 5, 11]}>
                      <Button
                        colorScheme="green"
                        rightIcon={<FaPlus />}
                        size="sm"
                        onClick={handleAdicionarItem}
                      >
                        Adicionar Item
                      </Button>
                    </FormGroup>
                  </Flex>
                  <div
                    style={{
                      marginTop: '2rem',
                      borderTop: '1px solid grey',
                      paddingTop: '1rem',
                    }}
                  >
                    <TituloPagina title="Resumo da Entrada" />
                    <Box
                      overflowX="auto"
                      overflowY="hidden"
                      whiteSpace="nowrap"
                      maxWidth="100%"
                      css={{
                        '&::-webkit-scrollbar': {
                          width: '4px',
                          height: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                          width: '6px',
                          height: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#ccc',
                          borderRadius: '24px',
                        },
                      }}
                    >
                      <Box
                        maxWidth={{
                          sm: '280px',
                          md: '100%',
                          lg: '100%',
                          xl: '100%',
                        }}
                      >
                        <Table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Peça</th>
                              <th>Tipo</th>
                              <th>Sexo</th>
                              <th>Lote</th>
                              <th>Tamanho</th>
                              <th>Quantidade</th>

                              <th
                                className="actions"
                                style={{ textAlign: 'center' }}
                              >
                                Ações
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {itensCarrinho &&
                              itensCarrinho.map((peca, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{peca.nome}</td>
                                  <td>{peca.tipo || 'Único'}</td>
                                  <td>{peca.sexo || 'Único'}</td>
                                  <td>{peca.lote}</td>
                                  <td>{peca.tamanho || 'Único'}</td>
                                  <td>{peca.quantidade}</td>
                                  <td className="actions">
                                    <HStack>
                                      <Tooltip
                                        label="Deletar"
                                        hasArrow
                                        placement="left"
                                      >
                                        <button type="button">
                                          <FiTrash2
                                            size={20}
                                            color="red"
                                            onClick={() =>
                                              handleDeletarItem(peca.id)
                                            }
                                          />
                                        </button>
                                      </Tooltip>
                                    </HStack>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Box>
                    </Box>
                  </div>
                </Accordion>
                <Accordion label="Histórico de Recebimentos da OPM" mt="2" defaultIndex={[0]} minWidth="700px">
                  <BoxContent>
                    <Box maxWidth="calc(100vw - 50px)">
                      <DataTable columns={colunas} options={options} />
                    </Box>
                  </BoxContent>
                </Accordion>

                <Flex mt="16px" direction="row" justifyContent="center">
                  <Button onClick={handleSair} size="sm" colorScheme="red">
                    Sair
                  </Button>

                  <Button ml="8px" type="submit" size="sm" colorScheme="green" onClick={handleAdicionarSaida}>
                    Reservar
                  </Button>
                </Flex>
              </Box>
              <ModelSav
                onClose={onCloseConfirmacaoMovimentacao}
                isOpen={isOpenConfirmacaoMovimentacao}
                size="xl"
                title="Itens do Pagamento"
              >
                <Container>
                  <Content style={{ textAlign: 'center', fontSize: '1.3rem' }}>
                    <b>
                      Você deseja confirmar o pagamento para a unidade {pessoaSelecionada.label} dos seguintes itens:

                      <Table>
                        <thead>
                          <tr>
                            <th className="tableCarrinhoModal">#</th>
                            <th className="tableCarrinhoModal">Peça</th>
                            <th className="tableCarrinhoModal">Tipo</th>
                            <th className="tableCarrinhoModal">Sexo</th>
                            <th className="tableCarrinhoModal">Lote</th>
                            <th className="tableCarrinhoModal">Tamanho</th>
                            <th className="tableCarrinhoModal">Quantidade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itensCarrinho &&
                            itensCarrinho.map((peca, index) => (
                              <tr key={index}>
                                <td className="tableCarrinhoModal">{index + 1}</td>
                                <td className="tableCarrinhoModal">{peca.nome}</td>
                                <td className="tableCarrinhoModal">{peca.tipo || 'Único'}</td>
                                <td className="tableCarrinhoModal">{peca.sexo || 'Único'}</td>
                                <td className="tableCarrinhoModal">{peca.lote}</td>
                                <td className="tableCarrinhoModal">{peca.tamanho || 'Único'}</td>
                                <td className="tableCarrinhoModal">{peca.quantidade}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </b>
                  </Content>
                  <PanelBottomActions>
                    <Button onClick={onCloseConfirmacaoMovimentacao} size="sm" colorScheme="red">
                      Sair
                    </Button>
                    {handleBotaoConfirmar()}
                  </PanelBottomActions>
                </Container>
              </ModelSav>
            </>
          )}
        </BoxContent>
      </>
    </>
  );
};

export default PagarFardamentoPm;
