import React from 'react';
import { MdEdit, MdDashboard, MdSearch, MdEqualizer } from 'react-icons/md';
import {
  Accordion,
  Center,
  Flex,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import Logo from '../components/Logo';
import MenuItem from './Menu';
import MenuDropdown from './MenuDropdown';
import LogoCetic from '../../../../assets/logo-cetic-35px.png';
import { Container, HeaderMenu, Footer } from './styles';
import { useAuth } from '../../../../contexts/auth';

interface ISideBarProps {
  activated: boolean;
  handleActiveSideBar(): void;
}

const cadastrosItems = [
  { label: 'ATUALIZAR FARDAMENTO', to: '/cadastrarpendentes' },
  { label: 'PAGAR FARDAMENTO PM', to: '/pagarfardamentopmopm' },
  { label: 'DEVOLUÇÃO PM', to: '/devolucaoPMopm' },
];

const cadastrosItemsColog = [
  { label: 'DEVOLUÇÃO OPM', to: '/devolucaoopmcolog' },
  { label: 'DEVOLUÇÃO PM', to: '/devolucaopmcolog' },
  { label: 'ESTOQUE', to: '/cadastrarestoque' },
  { label: 'FORNECEDORES', to: '/cadastrarfornecedor' },
  { label: 'ITENS', to: '/cadastraritem' },
  { label: 'PAGAR FARDAMENTO OPM', to: '/pagarfardamentoparaopm' },
  { label: 'PAGAR FARDAMENTO PM', to: '/pagarfardamentoopm' },
];

const editarUsuario = [{ label: 'ATUALIZAR FARDAMENTO', to: '/edituser' }];

const consultaUsuario = [
  { label: 'FARDAMENTOS RECEBIDOS', to: '/fardamentosrecebidospm' },
  { label: 'MEUS DOCUMENTOS', to: '/listasdocumentosparaassinar' },
];

const consultasItems = [
  { label: 'DEVOLUÇÃO COLOG', to: '/consultadevolucaoopm' },
  { label: 'DEVOLUÇÃO OPM', to: '/listardevolucaopmopm' },
  { label: 'ESTOQUE', to: '/listarestoqueopm' },
  { label: 'EFETIVO GERAL', to: '/listarfardamentos' },
  { label: 'EFETIVO PENDENTE', to: '/listarfardamentospendentes' },
  { label: 'RELATÓRIO QUANTITATIVO', to: '/relatorioquantitativo' },
  { label: 'FARDAMENTOS RECEBIDOS', to: '/fardamentosrecebidosopm' },
  { label: 'FARDAMENTOS PAGOS', to: '/documentosassinadosopm' },
  { label: 'EDITAR/CANCELAR PGTO', to: '/documentospendentesopm' },
];

const consultasPagamentosPmOpm = [
  { label: 'PAGAR PM', to: '/pagarfardamentoopm' },
];

const consultasItemsColog = [
  { label: 'DEVOLUÇÃO', to: '/devolucaofardamentoopmconsulta' },
  { label: 'EDITAR/CANCELAR PGTO', to: '/documentospendentescolog' },
  { label: 'EFETIVO GERAL', to: '/listarfardamentos' },
  { label: 'ENTRADAS', to: '/listarentradacolog' },
  { label: 'ESTOQUE', to: '/listarestoque' },
  { label: 'FORNECEDORES', to: '/listarfornecedores' },
  { label: 'ITENS', to: '/listaritens' },
  { label: 'SAIDA OPM', to: '/listarsaidaopmcolog' },
  { label: 'PAGAMENTOS PM', to: '/documentosassinadosvisaocologopm' },
  { label: 'PAGAMENTOS OPM', to: '/documentosassinadoscolog' },
];

const relatoriosItemsColog = [
  { label: 'RELATÓRIO PAGAMENTOS', to: '/relatoriopahamentoopmitens' },
  { label: 'RELATÓRIO QUANTITATIVO', to: '/relatorioquantitativo' },
  { label: 'RELATÓRIO QUANTITATIVO 2º PGTO', to: '/relatorioquantitativo2' },
  {
    label: 'RELATÓRIO QUANTITATIVO ALUNOS',
    to: '/relatorioquantitativoalunos',
  },
];

const SideBar: React.FC<ISideBarProps> = ({
  activated,
  handleActiveSideBar,
}) => {
  const { user } = useAuth();
  const bg = useColorModeValue('green.500', '#5b5b58');
  const color = useColorModeValue('gray.500', 'white');

  return (
    <Container activated={activated}>
      <HeaderMenu activated={activated}>
        <Logo activated={activated} />
      </HeaderMenu>

      <Flex
        bg={bg}
        textColor="black"
        color={color}
        direction="column"
        alignItems="initial"
        flex="1"
      >
        <Accordion allowToggle>
          {user.currentPerfil === 'SISFARD - OPM' && (
            <MenuItem
              to="/home"
              label="INICIAL"
              icon={MdDashboard}
              activated={activated}
            />
          )}

          {user.currentPerfil === 'SISFARD - COLOG' && (
            <MenuItem
              to="/homecolog"
              label="INICIAL"
              icon={MdDashboard}
              activated={activated}
            />
          )}

          {user.currentPerfil === 'SISFARD - COLOG' && (
            <MenuDropdown
              label="CADASTRO"
              icon={MdEdit}
              items={cadastrosItemsColog}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
            />
          )}

          {user.currentPerfil === 'SISFARD - COLOG' && (
            <MenuDropdown
              label="CONSULTA"
              icon={MdSearch}
              items={consultasItemsColog}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
            />
          )}

          {user.currentPerfil === 'SISFARD - COLOG' && (
            <MenuDropdown
              label="RELATÓRIO"
              icon={MdEqualizer}
              items={relatoriosItemsColog}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
            />
          )}

          {/* {user.currentPerfil === 'SISFARD - COLOG' && (
            <MenuDropdown
              label="PAGAMENTO"
              icon={MdCardTravel}
              items={consultasPagamentosPmOpm}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
            />
          )} */}

          {user.currentPerfil === 'SISFARD - OPM' && (
            <MenuDropdown
              label="CADASTRO"
              icon={MdEdit}
              items={cadastrosItems}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
            />
          )}

          {user.currentPerfil === 'SISFARD - OPM' && (
            <MenuDropdown
              label="CONSULTA"
              icon={MdSearch}
              items={consultasItems}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
            />
          )}

          {user.currentPerfil === 'SISFARD - BCG' && (
            <MenuDropdown
              label="CADASTRO"
              icon={MdEdit}
              items={editarUsuario}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
            />
          )}

          {user.currentPerfil === 'SISFARD - BCG' && (
            <MenuDropdown
              label="CONSULTA"
              icon={MdSearch}
              items={consultaUsuario}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
            />
          )}
        </Accordion>
      </Flex>
      <Footer activated={activated}>
        <Center w="100%">
          {activated && <Image src={LogoCetic} alt="logo cetic" />}
        </Center>
      </Footer>
    </Container>
  );
};

export default SideBar;
