import React, { createContext, useCallback, useState, useContext } from 'react';

interface ISaidaContextData {
  idSaida: number | undefined;
  updateIdSaida(id: number): Promise<void>;
}

const SaidaContext = createContext<ISaidaContextData>({} as ISaidaContextData);

const SaidaProvider: React.FC = ({ children }) => {
  const [idSaida, setIdSaida] = useState<number | undefined>(() => {
    const id = sessionStorage.getItem('@pmce-cetic-sisfard:id_saida');

    if (id) {
      return JSON.parse(id);
    }

    return undefined;
  });

  const updateIdSaida = useCallback(async (id: number) => {
    setIdSaida(id);
    sessionStorage.setItem('@pmce-cetic-sisfard:id_saida', JSON.stringify(id));
  }, []);

  return (
    <SaidaContext.Provider
      value={{
        idSaida,
        updateIdSaida,
      }}
    >
      {children}
    </SaidaContext.Provider>
  );
};

function useSaida(): ISaidaContextData {
  const context = useContext(SaidaContext);

  if (!context) {
    throw new Error('useSaida must be used within an SaidaProvider');
  }

  return context;
}

export { SaidaProvider, useSaida };
