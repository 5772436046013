import React from 'react';

import { AuthProvider } from './auth';
import { FardamentoProvider } from './fardamento';
import { ItemProvider } from './item';
import { FornecedorProvider } from './fornecedor';
import { EntradaCologProvider } from './entradaColog';
import { SaidaProvider } from './saida';
import { PolicialMilitarProvider } from './policialMilitar';
import { UnidadeProvider } from './unidade';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <EntradaCologProvider>
      <FornecedorProvider>
        <ItemProvider>
          <FardamentoProvider>
            <SaidaProvider>
              <PolicialMilitarProvider>
                <UnidadeProvider>{children}</UnidadeProvider>
              </PolicialMilitarProvider>
            </SaidaProvider>
          </FardamentoProvider>
        </ItemProvider>
      </FornecedorProvider>
    </EntradaCologProvider>
  </AuthProvider>
);

export default AppProvider;
