import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaTshirt } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';

import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { usePm } from '../../contexts/policialMilitar';
import { useFardamento } from '../../contexts/fardamento';
import DataTable, { IColumns } from '../../components/DataTable';

interface IFields {
  [key: string]: string | number;
}

interface IGraduacoes {
  gra_codigo: string;
  gra_nome: string;
  gra_sigla: string;
}

function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

const ListarFardamentos: React.FC = () => {
  const { user } = useAuth();
  const { matPolicialMilitar } = usePm();
  const { updateIdFardamento } = useFardamento();
  const history = useHistory();
  const query = useQuery();
  const [graduacoes, setGraduacoes] = useState<IGraduacoes[]>([] || undefined);
  const [graduacoesFormatado, setGraduacoesFormatado] = useState([]);

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdFardamento(id);

      history.push(`/editarfardamento`);
    },
    [updateIdFardamento, history],
  );

  const handleClickHistorico = useCallback(
    async (id: number) => {
      await matPolicialMilitar(id);

      history.push(`/historicofardamentopm`);
    },
    [matPolicialMilitar, history],
  );

  const colunas: IColumns = [
    {
      field: 'gra_nome',
      text: 'Post./Grad.',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pm_numero',
      text: 'Numeral',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pm_apelido',
      text: 'Nome de Guerra',
      type: {
        name: 'text',
      },
    },
    {
      field: 'tipo_sanguineo',
      text: 'Tipo Sanguíneo',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pm_codigo',
      text: 'Matrícula',
      type: {
        name: 'text',
      },
    },
    {
      field: 'uni_sigla',
      text: 'Opm',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pm_sexo',
      text: 'Sexo',
      type: {
        name: 'text',
      },
    },
    {
      field: 'situacao_func',
      text: 'Situação Funcional',
      type: {
        name: 'text',
      },
    },
    {
      field: 'situacao_func_cat',
      text: 'Situação Funcional Categoria',
      type: {
        name: 'text',
      },
    },
    {
      field: 'editou',
      text: 'Situação',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/policiais/uniformes/opm/${user.currentOpm?.uni_codigo}`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            handleClickEditar(uniforme.peu_codigo);
          },
        },
        {
          icon: <FaTshirt size={13} />,
          tooltip: 'Histórico Fardamento',

          getRow: (uniforme: any) => {
            handleClickHistorico(uniforme.pm_codigo);
          },
        },
      ],
    },
    filters: [
      // maxWidth="calc(100vw - 40px)"
      {
        field: 'editou',
        label: 'Situação',
        options: [
          { value: 'TODOS', label: 'TODOS' },
          { value: 'PENDENTE', label: 'PENDENTE' },
          { value: 'ATUALIZADO', label: 'ATUALIZADO' },
        ],
        defaultOption: query.get('peu_editou') ? query.get('peu_editou') : '',
      },
    ],
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['pm_numero', 'pm_codigo', 'gra_nome', 'editou'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
    exportCsv: {
      visible: true,
      label: 'Baixar planilha',
      /* headers: [
        'Codigo',
        'Nº PM',
        'Cabeça',
        'Calça',
        'Sapato',
        'Gandola',
        'Blusa Interna',
        'Coldre',
        'Calça Sexo',
        'Gandola Sexo',
        'Unidade Cod',
        'Unidade',
        'Unidade Superior',
        'Unidade Grande Comando',
        'Matricula',
        'Nome Guerra',
        'Sexo',
        'Grad',
        'Grad_Nome',
        'Editou',
        'Data Alteracao',
      ], */
      columns: [
        { field: 'uni_sigla', title: 'Unidade' },
        { field: 'gra_nome', title: 'Graduação' },
        { field: 'pm_numero', title: 'Nº PM' },
        { field: 'pm_apelido', title: 'Nome Guerra' },
        { field: 'tipo_sanguineo', title: 'Tipo Sanguíneo' },
        { field: 'pm_codigo', title: 'Matricula' },
        { field: 'peu_cabeca', title: 'Cabeça' },
        { field: 'peu_blusa_interna', title: 'Blusa Interna' },
        { field: 'peu_coldre', title: 'Coldre' },
        { field: 'peu_sapato', title: 'Sapato' },
        { field: 'peu_calca', title: 'Calça' },
        { field: 'peu_calca_sexo', title: 'Calça Sexo' },
        { field: 'peu_gandola', title: 'Gandola' },
        { field: 'peu_gandola_sexo', title: 'Gandola Sexo' },
        { field: 'peu_combatshirt', title: 'Camisa Tática' },
        { field: 'pm_sexo', title: 'Sexo' },
        { field: 'situacao_func', title: 'Situação Funcional' },
        { field: 'situacao_func_cat', title: 'Situação Funcional Categoria' },
        { field: 'editou', title: 'Atualização do Fardamento' },
      ],
      filename: `lista-fardamento-${user.currentOpm?.uni_sigla}`,
    },
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
    };
    loadCounts();
  }, [user]);

  return (
    <>
      <TituloPagina title="Relação Geral da OPM" />
      <BoxContent>
        <Box maxWidth="calc(100vw - 50px)">
          <DataTable columns={colunas} options={options} />
        </Box>
      </BoxContent>
    </>
  );
};

export default ListarFardamentos;
