import styled from 'styled-components';

export const Table = styled.table`
  margin-top: 8px;
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
  margin-top tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody {
    width: 100%;
  }

  tr {
    border-bottom: 1px solid #ddd;
  }

  th,
  td {
    padding: 8px;
    text-align: left;
  }

  th.actions {
    width: 160px;
  }
  td.actions {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 160px;
    margin: 0;

    button {
      background: none;
      border: none;
    }
    > div + div {
      margin-left: 8px;
    }
  }
  .tableCarrinhoModal {
    text-align: center;
  }
`;

export const Content = styled.p`
  text-align: justify;
`;

export const PdfLink = styled.a`
  margin-top: 16px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: black;
  svg {
    margin-bottom: 8px;
  }
`;
