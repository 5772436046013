// ListarEstoque
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaSearch } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import {
  VStack,
  SimpleGrid,
  useToast,
  Stack,
  HStack,
  Flex,
  Button,
} from '@chakra-ui/react';
import api from '../../services/api';

import BoxContent from '../../components/BoxContent';
import { useItem } from '../../contexts/item';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useEntradaColog } from '../../contexts/entradaColog';
import DataTable, { IColumns } from '../../components/DataTable';

interface IFields {
  [key: string]: string | number;
}

function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

const ListarEstoqueHistoricoItemEntradaColog: React.FC = () => {
  const { user } = useAuth();

  const { idEntradaColog } = useEntradaColog();
  const [entrada, setEntrada] = useState<any>();
  const history = useHistory();
  const query = useQuery();

  const handleClickEditar = useCallback(
    async (id: number) => {
      console.log(id);

      history.push(`/editarfardamento`);
    },
    [history],
  );

  const handleSair = (): void => {
    history.push('/listarentradacolog');
  };

  const colunas: IColumns = [
    {
      field: 'item.nome',
      text: 'Item de Fardamento',
      type: {
        name: 'text',
      },
    },
    {
      field: 'item.tamanho',
      text: 'Tamanho',
      type: {
        name: 'text',
      },
    },
    {
      field: 'item.sexo',
      text: 'Sexo',
      type: {
        name: 'text',
      },
    },
    {
      field: 'item.tipo_batalhao',
      text: 'Tipo',
      type: {
        name: 'text',
      },
    },
    {
      field: 'item.modalidade_coturno',
      text: 'Modalidade',
      type: {
        name: 'text',
      },
    },
    {
      field: 'lote',
      text: 'Lote',
      type: {
        name: 'text',
      },
    },
    {
      field: 'quantidade',
      text: 'Quantidade',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/itensentradascolog/entradacolog`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `id_entrada_colog=${idEntradaColog}`,
    },
    /*  actions: {
      headerText: 'Detalhes',
      items: [
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            handleClickEditar(uniforme.id_entrada_colog);
          },
        },
      ],
    }, */
    filters: [
      // maxWidth="calc(100vw - 40px)"
    ],
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['nota_fiscal'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }

      const entradacolog = await api.get(`/entradascolog/${idEntradaColog}`);
      setEntrada(entradacolog.data);
    };
    loadCounts();
  }, [user]);

  return (
    <>
      {entrada && (
        <>
          <TituloPagina
            title={`Historico de itens de estoque na nota fiscal: ${entrada.nota_fiscal} `}
          />
          <BoxContent>
            <Box maxWidth="calc(100vw - 50px)">
              <DataTable columns={colunas} options={options} />
              <Flex mt="8" justify="center">
                <HStack spacing="4">
                  <Button as="a" colorScheme="red" onClick={handleSair}>
                    Voltar
                  </Button>
                </HStack>
              </Flex>
            </Box>
          </BoxContent>
        </>
      )}
    </>
  );
};

export default ListarEstoqueHistoricoItemEntradaColog;
