import React, { useCallback, useEffect, useState } from 'react';
import { FaSearch, FaEdit, FaTrash } from 'react-icons/fa';
import { Box, useToast } from '@chakra-ui/react';
import { Flex, useDisclosure, Button, HStack } from '@chakra-ui/react';

import { useHistory, useLocation } from 'react-router-dom';

import api from '../../services/api';
import Modal from '../../components/Modal';

import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useItem } from '../../contexts/item';
import DataTable, { IColumns } from '../../components/DataTable';

const ListarItens: React.FC = () => {
  const { user } = useAuth();
  const { updateIdItem } = useItem();
  const history = useHistory();
  const toast = useToast();
  const [itemModal, setItemModal] = useState<any>();

  const handleClickShow = useCallback(
    async (id: number) => {
      await updateIdItem(id);

      history.push(`/showitem`);
    },
    [updateIdItem, history],
  );

  const {
    isOpen: isOpenVisualizar,
    onOpen: onOpenVisualizar,
    onClose: onCloseVisualizar,
  } = useDisclosure();

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdItem(id);

      history.push(`/editaritem`);
    },
    [updateIdItem, history],
  );

  const handleExcluirItem = async (data: any): Promise<any> => {
    try {
      const deletarItem = await api.delete(
        `/itens/${data.id_item}`, // criar uma rota pra deletar
      );
      toast({
        title: 'Sucesso.',
        description: 'Modalidade de Irso Excluido com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
      history.push('/listaritens');
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
      history.push('/listaritens');
    }
  };

  const colunas: IColumns = [
    {
      field: 'categoria.nome',
      text: 'Categoria',
      type: {
        name: 'text',
      },
    },
    {
      field: 'nome',
      text: 'Peça',
      type: {
        name: 'text',
      },
    },

    {
      field: 'sexo',
      text: 'Sexo',
      type: {
        name: 'text',
      },
    },
    {
      field: 'tamanho',
      text: 'Tamanho',
      type: {
        name: 'text',
      },
    },
    {
      field: 'tipo_batalhao',
      text: 'Tipo',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/itens`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      // params: `subunidades=${user.verSubunidade}`,
    },
    actions: {
      headerText: 'Editar',
      items: [
        {
          icon: <FaSearch size={13} />,
          tooltip: 'Visualizar',

          getRow: (uniforme: any) => {
            handleClickShow(uniforme.id_item);
          },
        },
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            handleClickEditar(uniforme.id_item);
          },
        },
        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',

          getRow: (uniforme: any) => {
            setItemModal(uniforme);
            onOpenVisualizar();
          },
        },
      ],
    },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['pm_numero', 'pm_codigo', 'gra_nome', 'editou'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }
    }
    load();
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
    };
    loadCounts();
  }, [user]);

  return (
    <>
      <TituloPagina title="Itens de Fardamento" />
      <BoxContent>
        <Box maxWidth="calc(100vw - 50px)">
          <DataTable columns={colunas} options={options} />
        </Box>
        {itemModal && (
          <Modal
            isOpen={isOpenVisualizar}
            onClose={onCloseVisualizar}
            size="lg"
            title={`Solicitação de Exclusao (Usuário: ${user.graduacao?.gra_sigla} ${user.pm_apelido})`}
          >
            <h1>
              Voce esta prestes a deletar um item de Fardamento{' '}
              <strong>
                {itemModal.nome} - {itemModal.tamanho}
              </strong>{' '}
              tem certeza da operação?
            </h1>
            <Flex mt="8" justify="center">
              <HStack spacing="4">
                <Button onClick={onCloseVisualizar} colorScheme="green">
                  Não
                </Button>

                <Button
                  onClick={() => handleExcluirItem(itemModal)}
                  colorScheme="red"
                >
                  Sim Quero Deletar!
                </Button>
              </HStack>
            </Flex>
          </Modal>
        )}
      </BoxContent>
    </>
  );
};

export default ListarItens;
