// ListarEstoque
import React, { useCallback, useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';

import { useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';

import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useUnidade } from '../../contexts/unidade';
import { useEntradaColog } from '../../contexts/entradaColog';

import { useItem } from '../../contexts/item';
import DataTable, { IColumns } from '../../components/DataTable';

interface IFields {
  [key: string]: string | number;
}

function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

const ListarSaidaOpmCologDetalhes: React.FC = () => {
  const { user } = useAuth();
  const { idUniCodigo } = useUnidade();
  const { idItem } = useItem();
  const { updateIdEntradaColog } = useEntradaColog();
  const [item, setItem] = useState<any>();

  const history = useHistory();
  const query = useQuery();

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdEntradaColog(id);
      history.push(`/listaritensentradacologhistorico`);
    },
    [updateIdEntradaColog, history],
  );

  const colunas: IColumns = [
    {
      field: 'cod_pm',
      text: 'Policial Recebedor',
      type: {
        name: 'text',
      },
    },
    {
      field: 'despachado_por',
      text: 'Policial Pagador',
      type: {
        name: 'text',
      },
    },
    {
      field: 'data_saida',
      text: 'Data Pagamento',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
    },
    {
      field: 'path_documento',
      text: 'Documento',
      type: {
        name: 'text',
      },
    },
    {
      field: 'quantidade',
      text: 'Quantidade Paga',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/itenssaidascolog/listarsaidaopmdetalhes`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `id_item=${idItem}&opm=${idUniCodigo}&colog=true`,
    },

    filters: [
      // maxWidth="calc(100vw - 40px)"
    ],
    search: {
      searchable: true,
      label: 'Pesquisar/Filtrar',
      fields: ['nota_fiscal'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }
    };
    loadCounts();
    const itensRequest = async (): Promise<any> => {
      const listaItens = await api.get(`/itens/pesquisa/${idItem}`);
      setItem(listaItens.data);
    };
    itensRequest();
  }, [user]);

  return (
    <>
      {item && (
        <>
          <TituloPagina title={`Item: ${item.pesquisa}`} />
          <BoxContent>
            <Box maxWidth="calc(100vw - 50px)">
              <DataTable columns={colunas} options={options} />
            </Box>
          </BoxContent>
        </>
      )}
    </>
  );
};

export default ListarSaidaOpmCologDetalhes;
