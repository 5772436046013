/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormGroup from 'components/form/FormGroup';
import {
  VStack,
  SimpleGrid,
  Box,
  useToast,
  Stack,
  HStack,
  Flex,
  Button,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/form/FormInput';
// import InputMask from 'react-input-mask';
import { max } from 'lodash';
import BoxContent from '../../components/BoxContent';

import api from '../../services/api';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

interface IEntrada {
  nome: string;
  endereco: string;
  telefone: string;
  cnpj: string;
}

const schema = Yup.object().shape({
  nome: Yup.string().required('Campo Obrigatório'),
  endereco: Yup.string().required('Campo Obrigatório'),
  telefone: Yup.string().required('Campo Obrigatório'),
  cnpj: Yup.string().required('Campo Obrigatório'),
});

const CadastrarFornecedor: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  const history = useHistory();
  const { errors, handleSubmit, register } = useForm<IEntrada>({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: undefined,
      endereco: undefined,
      telefone: undefined,
      cnpj: undefined,
    },
  });

  const handleSair = (): void => {
    history.push('/listarfornecedores');
  };

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }
    }
    load();
  }, [user]);

  const formatacaoTelefone = (value: any): any => {
    return value
      .replace(/\D/g, '')
      .replace(
        /(?:(^\+\d{2})?)(?:([1-9]{2})|([0-9]{3})?)(\d{4,5})(\d{4})/,
        (fullMatch: any, country: any, ddd: any, dddWithZero: any, prefixTel: any, suffixTel: any): any => {
          if (country)
            return `${country} (${ddd || dddWithZero
              }) ${prefixTel}-${suffixTel}`;
          if (ddd || dddWithZero)
            return `(${ddd || dddWithZero}) ${prefixTel}-${suffixTel}`;
          if (prefixTel && suffixTel) return `${prefixTel}-${suffixTel}`;
          return value;
        },
      );
  };

  const handleAdicionarFornecedor = async (data: any): Promise<any> => {
    const dados = {
      ...data,
      criado_por: user.matricula,
    };
    try {
      const inserirEstoque = await api.post(`/fornecedores`, dados);
      toast({
        title: 'Sucesso.',
        description: 'Cadastro de fornecedor inserido com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listarfornecedores');
    } catch (error) {
      console.log(error);
      toast({
        title: 'Erro',
        description: 'Erro ao inserir o fornecedor',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <Stack>
      <TituloPagina title="Cadrasto de Fornecedor" />
      <BoxContent>
        <Box
          as="form"
          flex="1"
          onSubmit={handleSubmit(handleAdicionarFornecedor)}
        >
          <VStack spacing="4">
            <SimpleGrid minChildWidth="300px" spacing={['2', '4']} w="100%">
              <FormGroup name="Nome Fornecedor">
                <FormInput
                  name="nome"
                  register={register}
                  error={errors.nome?.message}
                />
              </FormGroup>
              <FormGroup name="Endereço">
                <FormInput
                  name="endereco"
                  register={register}
                  error={errors.endereco?.message}
                />
              </FormGroup>
              <FormGroup name="Telefone">
                <FormInput
                  name="telefone"
                  register={register}
                  error={errors.telefone?.message}
                  placeholder="(XX) 90000-0000"
                  maxLength={15}
                  id="telefone"
                  onChange={(e) => {
                    const { value } = e.target;
                    e.target.value = formatacaoTelefone(value);
                  }}
                />
                {/* <InputMask
                  type="tel"
                  name="telefone"
                  id="telefone"
                  placeholder="(99) 99999-9999"
                  mask="(99) 99999-9999"
                /> */}
              </FormGroup>
              <FormGroup name="CNPJ">
                <FormInput
                  name="cnpj"
                  register={register}
                  error={errors.cnpj?.message}
                />
              </FormGroup>
            </SimpleGrid>
            <Flex mt="8" justify="flex-end">
              <HStack spacing="4">
                <Button onClick={handleSair} as="a" colorScheme="red">
                  Voltar
                </Button>

                <Button colorScheme="green" type="submit">
                  Salvar
                </Button>
              </HStack>
            </Flex>
          </VStack>
        </Box>
      </BoxContent>
    </Stack>
  );
};

export default CadastrarFornecedor;
