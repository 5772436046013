import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormGroup from 'components/form/FormGroup';
import {
  VStack,
  SimpleGrid,
  Box,
  useToast,
  Stack,
  HStack,
  Flex,
  Button,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/form/FormInput';
import { Select } from '../../components/form/Select';
import FormTextArea from '../../components/form/FormTextArea';
import BoxContent from '../../components/BoxContent';
import api from '../../services/api';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

interface IEntrada {
  id_categoria: string;
  nome: string;
  sexo?: string;
  tamanho?: string;
  lateralidade_corporal?: string;
  modalidade_coturno?: string;
  alias?: string;
  descricao?: string;
  tipo_batalhao: string;
}

const schema = Yup.object().shape({
  id_categoria: Yup.string().required('Campo Obrigatório'),
  nome: Yup.string().required('Campo Obrigatório'),
  sexo: Yup.string().when('nome', {
    is: (nome) => {
      if (nome === 'Gandola') {
        return true;
      }
      if (nome === 'Calça') {
        return true;
      }
      return false;
    },
    then: Yup.string().required('Campo Obrigatório'),
  }),
  tamanho: Yup.string().when('nome', {
    is: (nome) => {
      if (nome === 'Coldre') {
        return false;
      }
      return true;
    },
    then: Yup.string().required('Campo Obrigatório'),
  }),

  modalidade_coturno: Yup.string().when('nome', {
    is: (nome) => {
      if (nome === 'Coturno') {
        return true;
      }
      return false;
    },
    then: Yup.string().required('Campo Obrigatório'),
  }),

  lateralidade_corporal: Yup.string().when('nome', {
    is: (nome) => {
      if (nome === 'Coldre') {
        return true;
      }
      return false;
    },
    then: Yup.string().required('Campo Obrigatório'),
  }),

  tipo_batalhao: Yup.string().when('nome', {
    is: (nome) => {
      if (nome === 'Blusa') {
        return false;
      }
      if (nome === 'Coldre') {
        return false;
      }
      return true;
    },
    then: Yup.string().required('Campo Obrigatório'),
  }),
});

const CadastrarItem: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  const history = useHistory();
  const [categoria, setCategoria] = useState<any>();
  const [lateralidadeSelect, setLateralidadeSelect] = useState<boolean>(false);
  const [sexoSelect, setSexoSelect] = useState<boolean>(true);
  const [coturnoSelect, setCoturnoSelect] = useState<boolean>(false);

  const { errors, handleSubmit, register } = useForm<IEntrada>({
    resolver: yupResolver(schema),
    defaultValues: {
      id_categoria: undefined,
      nome: undefined,
      sexo: undefined,
      tamanho: undefined,
      lateralidade_corporal: undefined,
      modalidade_coturno: undefined,
      alias: undefined,
      descricao: undefined,
      tipo_batalhao: undefined,
    },
  });

  const handleAdicionarItem = async (data: any): Promise<any> => {
    const dados = {
      ...data,
      criado_por: user.matricula,
    };

    try {
      const inserirItem = await api.post(`/itens`, dados);
      toast({
        title: 'Sucesso.',
        description: 'Item de Fardamento inserido com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listaritens');
    } catch (error) {
      // console.log(error.response.status);
      error.response.status === 409
        ? toast({
            title: 'Erro',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          })
        : toast({
            title: 'Erro',
            description: 'Erro ao inserir o item de fardamento',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
    }
  };

  const handleSair = (): void => {
    history.push('/listaritens');
  };

  const optionsMascFem = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
    { value: 'U', label: 'Unisex' },
  ];

  const optionsColdre = [
    { value: 'D', label: 'Destro' },
    { value: 'C', label: 'Canhoto' },
  ];

  const optionsModalidadeCoturno = [
    { value: 'GERAL', label: 'GERAL' },
    { value: 'CAVALARIA', label: 'CAVALARIA' },
    { value: 'MOTOCICLISTA', label: 'MOTOCICLISTA' },
  ];

  const optionsBatalhoes = [
    { value: 'POG', label: 'POG' },
    { value: 'RAIO', label: 'RAIO' },
    { value: 'CHOQUE', label: 'CHOQUE' },
    { value: 'CAVALARIA', label: 'CAVALARIA' },
    { value: 'COTAR', label: 'COTAR' },
    { value: 'GATE', label: 'GATE' },
    { value: 'BPMA', label: 'BPMA' },
  ];

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }
    }
    load();
    const categorias = async (): Promise<any> => {
      const listaCategorias = await api.get('/categorias');
      const optionsFornecedores = listaCategorias.data.map(
        (fornecedor: any) => {
          return {
            value: fornecedor.id_categoria,
            label: fornecedor.nome,
          };
        },
      );
      setCategoria(optionsFornecedores);
    };

    categorias();
  }, [user]);

  return (
    <Stack>
      <TituloPagina title="Cadrasto de Item de Fardamento" />
      <BoxContent>
        <Box as="form" flex="1" onSubmit={handleSubmit(handleAdicionarItem)}>
          <VStack spacing="4">
            <SimpleGrid minChildWidth="300px" spacing={['2', '4']} w="100%">
              <FormGroup name="Categoria">
                <Select
                  placeholder="Selecione uma Opção"
                  onChange={(e) => {
                    e.target.value === '2'
                      ? setLateralidadeSelect(true)
                      : setLateralidadeSelect(false);
                  }}
                  name="id_categoria"
                  ref={register}
                  options={categoria}
                  error={errors.id_categoria}
                />
              </FormGroup>
              <FormGroup name="Nome">
                <FormInput
                  name="nome"
                  onChange={(e) => {
                    e.target.value === 'Blusa'
                      ? setSexoSelect(false)
                      : setSexoSelect(true);

                    e.target.value === 'Coturno'
                      ? setCoturnoSelect(true)
                      : setCoturnoSelect(false);
                  }}
                  register={register}
                  error={errors.nome?.message}
                />
              </FormGroup>

              <FormGroup name="Sexo">
                <Select
                  placeholder="Selecione uma Opção"
                  name="sexo"
                  ref={register}
                  options={optionsMascFem}
                  error={errors.sexo}
                />
              </FormGroup>

              <FormGroup name="Tamanho">
                <FormInput
                  maxLength={4}
                  name="tamanho"
                  register={register}
                  error={errors.tamanho?.message}
                />
              </FormGroup>

              <FormGroup name="Tipo">
                <Select
                  placeholder="Selecione uma Opção"
                  name="tipo_batalhao"
                  ref={register}
                  options={optionsBatalhoes}
                  error={errors.tipo_batalhao}
                />
              </FormGroup>

              <FormGroup name="Modalidade">
                <Select
                  placeholder="Selecione uma Opção"
                  name="modalidade_coturno"
                  ref={register}
                  options={optionsModalidadeCoturno}
                  error={errors.modalidade_coturno}
                />
              </FormGroup>

              <FormGroup name="Lateralidade Corporal">
                <Select
                  placeholder="Selecione uma Opção"
                  name="lateralidade_corporal"
                  ref={register}
                  options={optionsColdre}
                  error={errors.lateralidade_corporal}
                />
              </FormGroup>
            </SimpleGrid>
            <SimpleGrid minChildWidth="300px" spacing={['2', '4']} w="100%">
              <FormGroup name="Descrição">
                <FormTextArea
                  placeholder="Digite uma descrição"
                  register={register}
                  name="descricao"
                />
              </FormGroup>
            </SimpleGrid>
            <Flex mt="8" justify="flex-end">
              <HStack spacing="4">
                <Button onClick={handleSair} colorScheme="red">
                  Voltar
                </Button>

                <Button colorScheme="green" type="submit">
                  Salvar
                </Button>
              </HStack>
            </Flex>
          </VStack>
        </Box>
      </BoxContent>
    </Stack>
  );
};

export default CadastrarItem;
