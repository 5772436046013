import React, { createContext, useCallback, useState, useContext } from 'react';

interface IUniContextData {
  idUniCodigo: number | undefined;
  codUnidade(id: number): Promise<void>;
}

const UnidadeContext = createContext<IUniContextData>({} as IUniContextData);

const UnidadeProvider: React.FC = ({ children }) => {
  const [idUniCodigo, setidUniCodigo] = useState<number | undefined>(() => {
    const id = sessionStorage.getItem('@pmce-cetic-sisfard:uni_codigo');

    if (id) {
      return JSON.parse(id);
    }

    return undefined;
  });

  const codUnidade = useCallback(async (id: number) => {
    setidUniCodigo(id);
    sessionStorage.setItem(
      '@pmce-cetic-sisfard:uni_codigo',
      JSON.stringify(id),
    );
  }, []);

  return (
    <UnidadeContext.Provider
      value={{
        idUniCodigo,
        codUnidade,
      }}
    >
      {children}
    </UnidadeContext.Provider>
  );
};

function useUnidade(): IUniContextData {
  const context = useContext(UnidadeContext);

  if (!context) {
    throw new Error('useUnidade must be used within an PmProvider');
  }

  return context;
}

export { UnidadeProvider, useUnidade };
