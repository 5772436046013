import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { Box } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';

import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useFardamento } from '../../contexts/fardamento';
import DataTable, { IColumns } from '../../components/DataTable';

interface IFields {
  [key: string]: string | number;
}

interface IGraduacoes {
  gra_codigo: string;
  gra_nome: string;
  gra_sigla: string;
}

function useQuery(): any {
  return new URLSearchParams(useLocation().search);
}

const ListarFardamentos: React.FC = () => {
  const { user } = useAuth();
  const { updateIdFardamento } = useFardamento();
  const history = useHistory();
  const query = useQuery();
  const [graduacoes, setGraduacoes] = useState<IGraduacoes[]>([] || undefined);
  const [graduacoesFormatado, setGraduacoesFormatado] = useState([]);

  const handleClickEditar = useCallback(
    async (id: number) => {
      await updateIdFardamento(id);

      history.push(`/editarfardamento`);
    },
    [updateIdFardamento, history],
  );

  const colunas: IColumns = [
    {
      field: 'gra_nome',
      text: 'Post./Grad.',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pm_numero',
      text: 'Numeral',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pm_apelido',
      text: 'Nome de Guerra',
      type: {
        name: 'text',
      },
    },
    {
      field: 'pm_codigo',
      text: 'Matrícula',
      type: {
        name: 'text',
      },
    },
    {
      field: 'editou',
      text: 'Situação',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/policiais/uniformes/opm/pendentes/${user.currentOpm?.uni_codigo}`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}`,
    },
    actions: {
      headerText: 'Editar',
      items: [
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            handleClickEditar(uniforme.peu_codigo);
          },
        },
      ],
    },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['pm_numero', 'pm_codigo', 'gra_nome', 'editou'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
    exportCsv: {
      visible: true,
      label: 'Baixar planilha',
      /* headers: [
        'Codigo',
        'Nº PM',
        'Cabeça',
        'Calça',
        'Sapato',
        'Gandola',
        'Blusa Interna',
        'Coldre',
        'Calça Sexo',
        'Gandola Sexo',
        'Unidade Cod',
        'Unidade',
        'Unidade Superior',
        'Unidade Grande Comando',
        'Matricula',
        'Nome Guerra',
        'Grad_Nome',
        'Grad_sigla',
        'Editou',
        'Data Alteracao',
      ], */
      columns: [
        { field: 'uni_sigla', title: 'Unidade' },
        { field: 'gra_nome', title: 'Graduação' },
        { field: 'pm_numero', title: 'Nº PM' },
        { field: 'pm_apelido', title: 'Nome Guerra' },
        { field: 'pm_codigo', title: 'Matricula' },
        { field: 'peu_cabeca', title: 'Cabeça' },
        { field: 'peu_blusa_interna', title: 'Blusa Interna' },
        { field: 'peu_coldre', title: 'Coldre' },
        { field: 'peu_sapato', title: 'Sapato' },
        { field: 'peu_calca', title: 'Calça' },
        { field: 'peu_calca_sexo', title: 'Calça Sexo' },
        { field: 'peu_gandola', title: 'Gandola' },
        { field: 'peu_gandola_sexo', title: 'Gandola Sexo' },
        { field: 'pm_sexo', title: 'Sexo' },
      ],
      filename: 'fardamentos',
    },
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      try {
        const resultado = await api.get(`/policiais/graduacoes`);
        setGraduacoes(
          resultado.data.map((objeto: any) => {
            return { [objeto.gra_codigo]: objeto.gra_sigla };
          }),
        );
      } catch (error) {
        console.log('Ocorreu um erro');
      }
    };
    loadCounts();
  }, [user]);

  return (
    <>
      {graduacoes && (
        <>
          <TituloPagina title="Relação Geral da OPM" />
          <BoxContent>
            <Box maxWidth="calc(100vw - 50px)">
              <DataTable columns={colunas} options={options} />
            </Box>
          </BoxContent>
        </>
      )}
    </>
  );
};

export default ListarFardamentos;
