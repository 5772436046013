import React, { useEffect, useState } from 'react';
import api from 'services/api';

import { useHistory } from 'react-router-dom';
import {
  Box,
  Center,
  Button,
  Flex,
  Text,
  useDisclosure,
  Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Select,
  Tr,
  Th,
  Td,
  TableCaption,
  useToast,
  FormErrorMessage,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import ExportCSV from './ExportCSV';
import Accordion from '../../components/Accordion';

import DashPanel from './DashPanel';
import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

interface ICounts {
  total: number;
  assinaturas: number;
  validacoes: number;
}

interface IDataDashboard {
  pessoa_uniforme_peu_codigo: number;
  pessoa_uniforme_pes_codigo: string;
  pessoa_uniforme_peu_editou: number;
}

interface IDataDashboardColog {
  identificador: number;
  codigoopm: string;
  opm_nm: string;
  opm_superior: string;
  opm_grd_cmdo: string;
  editou: string;
  nao_editou: string;
  ordemopm: string;
  total: number;
}

const HomeColog: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [dashboardcologdata, setDashboardcologdata] = useState<
    IDataDashboardColog[]
  >([]);

  const dataValidacoes = {
    labels: ['Concluídas', 'Restantes'],
    datasets: [
      {
        label: 'Validações',
        data: [
          dashboardcologdata.reduce((valorAtual, objeto) => {
            return valorAtual + Number(objeto.editou);
          }, 0) || 0,
          dashboardcologdata.reduce((valorAtual, objeto) => {
            return valorAtual + Number(objeto.total);
          }, 0) -
            dashboardcologdata.reduce((valorAtual, objeto) => {
              return valorAtual + Number(objeto.editou);
            }, 0),
        ],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }
      if (user.currentPerfil === 'SISFARD - COLOG') {
        history.push('/homecolog');
      }
      try {
        const resultado = await api.get(
          `/policiais/uniformes/dashboardcolog/listar`,
        );
        setDashboardcologdata(resultado.data);
      } catch (error) {
        console.log('Ocorreu um erro');
      }
    };
    loadCounts();
  }, [user]);
  return (
    <>
      {dashboardcologdata && user.currentPerfil === 'SISFARD - COLOG' && (
        <>
          <TituloPagina title="Painel Principal" />
          <BoxContent>
            <Flex direction="row" justifyContent="space-around" wrap="wrap">
              <DashPanel
                color="#67C58D"
                data={dataValidacoes}
                title="Fardamentos Atualizados"
                opm="Policia militar do Ceará"
                onClick={() => history.push('/validacoes?email=VALIDADO')}
              />
            </Flex>
            <Accordion
              label={`Unidades Administrativas - Concluido: ${(
                (dashboardcologdata
                  .filter(
                    (objeto) =>
                      objeto.opm_grd_cmdo === 'UNIDADES ADMINISTRATIVAS',
                  )
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.editou);
                  }, 0) /
                  dashboardcologdata
                    .filter(
                      (objeto) =>
                        objeto.opm_grd_cmdo === 'UNIDADES ADMINISTRATIVAS',
                    )
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter(
                  (objeto) =>
                    objeto.opm_grd_cmdo === 'UNIDADES ADMINISTRATIVAS',
                )
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.editou);
                }, 0)}/${dashboardcologdata
                .filter(
                  (objeto) =>
                    objeto.opm_grd_cmdo === 'UNIDADES ADMINISTRATIVAS',
                )
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)}) - Restante: ${(
                (dashboardcologdata
                  .filter(
                    (objeto) =>
                      objeto.opm_grd_cmdo === 'UNIDADES ADMINISTRATIVAS',
                  )
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.nao_editou);
                  }, 0) /
                  dashboardcologdata
                    .filter(
                      (objeto) =>
                        objeto.opm_grd_cmdo === 'UNIDADES ADMINISTRATIVAS',
                    )
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter(
                  (objeto) =>
                    objeto.opm_grd_cmdo === 'UNIDADES ADMINISTRATIVAS',
                )
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.nao_editou);
                }, 0)}/${dashboardcologdata
                .filter(
                  (objeto) =>
                    objeto.opm_grd_cmdo === 'UNIDADES ADMINISTRATIVAS',
                )
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)}) `}
              mt="2"
            >
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Table variant="simple">
                    <TableCaption />
                    <Thead>
                      <Tr>
                        <Th> #</Th>
                        <Th>OPM</Th>
                        <Th>Executado</Th>
                        <Th>Faltando</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dashboardcologdata
                        .filter((objeto) => {
                          return (
                            objeto.opm_grd_cmdo === 'UNIDADES ADMINISTRATIVAS'
                          );
                        })
                        .map((objeto, index) => {
                          return (
                            <Tr key={index}>
                              <Td>{index + 1}</Td>
                              <Td>{objeto.opm_nm}</Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.editou);
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.nao_editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return (
                                      valorAtual + Number(objeto3.nao_editou)
                                    );
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td />
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </Accordion>

            <Accordion
              label={`1ºCRPM - Concluido: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === '1ºCRPM')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === '1ºCRPM')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '1ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '1ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)}) - Restante: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === '1ºCRPM')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.nao_editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === '1ºCRPM')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '1ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.nao_editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '1ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)})`}
              mt="2"
            >
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th> #</Th>
                        <Th>OPM</Th>
                        <Th>Executado</Th>
                        <Th>Faltando</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dashboardcologdata
                        .filter((objeto) => {
                          return objeto.opm_grd_cmdo === '1ºCRPM';
                        })
                        .map((objeto, index) => {
                          return (
                            <Tr>
                              <Td>{index + 1}</Td>
                              <Td>{objeto.opm_nm}</Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.editou);
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.nao_editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return (
                                      valorAtual + Number(objeto3.nao_editou)
                                    );
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td />
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </Accordion>

            <Accordion
              label={`2ºCRPM - Concluido: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === '2ºCRPM')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === '2ºCRPM')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '2ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '2ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)}) - Restante: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === '2ºCRPM')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.nao_editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === '2ºCRPM')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '2ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.nao_editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '2ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)})`}
              mt="2"
            >
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th> #</Th>
                        <Th>OPM</Th>
                        <Th>Executado</Th>
                        <Th>Faltando</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dashboardcologdata
                        .filter((objeto) => {
                          return objeto.opm_grd_cmdo === '2ºCRPM';
                        })
                        .map((objeto, index) => {
                          return (
                            <Tr>
                              <Td>{index + 1}</Td>
                              <Td>{objeto.opm_nm}</Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.editou);
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.nao_editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return (
                                      valorAtual + Number(objeto3.nao_editou)
                                    );
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td />
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </Accordion>

            <Accordion
              label={`3ºCRPM - Concluido: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === '3ºCRPM')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === '3ºCRPM')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '3ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '3ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)}) - Restante: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === '3ºCRPM')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.nao_editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === '3ºCRPM')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '3ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.nao_editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '3ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)})`}
              mt="2"
            >
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th> #</Th>
                        <Th>OPM</Th>
                        <Th>Executado</Th>
                        <Th>Faltando</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dashboardcologdata
                        .filter((objeto) => {
                          return objeto.opm_grd_cmdo === '3ºCRPM';
                        })
                        .map((objeto, index) => {
                          return (
                            <Tr>
                              <Td>{index + 1}</Td>
                              <Td>{objeto.opm_nm}</Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.editou);
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.nao_editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return (
                                      valorAtual + Number(objeto3.nao_editou)
                                    );
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td />
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </Accordion>

            <Accordion
              label={`4ºCRPM - Concluido: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === '4ºCRPM')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === '4ºCRPM')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '4ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '4ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)}) - Restante: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === '4ºCRPM')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.nao_editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === '4ºCRPM')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '4ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.nao_editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === '4ºCRPM')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)})`}
              mt="2"
            >
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th> #</Th>
                        <Th>OPM</Th>
                        <Th>Executado</Th>
                        <Th>Faltando</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dashboardcologdata
                        .filter((objeto) => {
                          return objeto.opm_grd_cmdo === '4ºCRPM';
                        })
                        .map((objeto, index) => {
                          return (
                            <Tr>
                              <Td>{index + 1}</Td>
                              <Td>{objeto.opm_nm}</Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.editou);
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.nao_editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return (
                                      valorAtual + Number(objeto3.nao_editou)
                                    );
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td />
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </Accordion>

            <Accordion
              label={`CPCHOQUE - Concluido: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === 'CPCHOQUE')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === 'CPCHOQUE')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPCHOQUE')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPCHOQUE')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)}) - Restante: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === 'CPCHOQUE')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.nao_editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === 'CPCHOQUE')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPCHOQUE')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.nao_editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPCHOQUE')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)})`}
              mt="2"
            >
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th> #</Th>
                        <Th>OPM</Th>
                        <Th>Executado</Th>
                        <Th>Faltando</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dashboardcologdata
                        .filter((objeto) => {
                          return objeto.opm_grd_cmdo === 'CPCHOQUE';
                        })
                        .map((objeto, index) => {
                          return (
                            <Tr>
                              <Td>{index + 1}</Td>
                              <Td>{objeto.opm_nm}</Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.editou);
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.nao_editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return (
                                      valorAtual + Number(objeto3.nao_editou)
                                    );
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td />
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </Accordion>

            <Accordion
              label={`CPE - Concluido: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === 'CPE')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === 'CPE')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPE')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPE')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)}) - Restante: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === 'CPE')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.nao_editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === 'CPE')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPE')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.nao_editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPE')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)})`}
              mt="2"
            >
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th> #</Th>
                        <Th>OPM</Th>
                        <Th>Executado</Th>
                        <Th>Faltando</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dashboardcologdata
                        .filter((objeto) => {
                          return objeto.opm_grd_cmdo === 'CPE';
                        })
                        .map((objeto, index) => {
                          return (
                            <Tr>
                              <Td>{index + 1}</Td>
                              <Td>{objeto.opm_nm}</Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.editou);
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.nao_editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return (
                                      valorAtual + Number(objeto3.nao_editou)
                                    );
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td />
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </Accordion>

            <Accordion
              label={`CPRAIO - Concluido: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === 'CPRAIO')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === 'CPRAIO')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPRAIO')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPRAIO')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)}) - Restante: ${(
                (dashboardcologdata
                  .filter((objeto) => objeto.opm_grd_cmdo === 'CPRAIO')
                  .reduce((valorAtual, objeto) => {
                    return valorAtual + Number(objeto.nao_editou);
                  }, 0) /
                  dashboardcologdata
                    .filter((objeto) => objeto.opm_grd_cmdo === 'CPRAIO')
                    .reduce((valorAtual, objeto) => {
                      return valorAtual + Number(objeto.total);
                    }, 0)) *
                100
              ).toFixed(2)}% (${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPRAIO')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.nao_editou);
                }, 0)}/${dashboardcologdata
                .filter((objeto) => objeto.opm_grd_cmdo === 'CPRAIO')
                .reduce((valorAtual, objeto) => {
                  return valorAtual + Number(objeto.total);
                }, 0)})`}
              mt="2"
            >
              <Flex direction="column" fontSize="0.90rem">
                <Flex direction="row">
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th> #</Th>
                        <Th>OPM</Th>
                        <Th>Executado</Th>
                        <Th>Faltando</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dashboardcologdata
                        .filter((objeto) => {
                          return objeto.opm_grd_cmdo === 'CPRAIO';
                        })
                        .map((objeto, index) => {
                          return (
                            <Tr>
                              <Td>{index + 1}</Td>
                              <Td>{objeto.opm_nm}</Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.editou);
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td>
                                {(
                                  (dashboardcologdata
                                    .filter((objeto2) => {
                                      return objeto2.opm_nm === objeto.opm_nm;
                                    })
                                    .reduce((valorAtual, objeto3) => {
                                      return (
                                        valorAtual + Number(objeto3.nao_editou)
                                      );
                                    }, 0) /
                                    dashboardcologdata
                                      .filter((objeto4) => {
                                        return objeto4.opm_nm === objeto.opm_nm;
                                      })
                                      .reduce((valorAtual, objeto5) => {
                                        return (
                                          valorAtual + Number(objeto5.total)
                                        );
                                      }, 0)) *
                                  100
                                ).toFixed(2)}
                                % (
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return (
                                      valorAtual + Number(objeto3.nao_editou)
                                    );
                                  }, 0)}
                                /
                                {dashboardcologdata
                                  .filter((objeto2) => {
                                    return objeto2.opm_nm === objeto.opm_nm;
                                  })
                                  .reduce((valorAtual, objeto3) => {
                                    return valorAtual + Number(objeto3.total);
                                  }, 0)}
                                )
                              </Td>
                              <Td />
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Flex>
              </Flex>
            </Accordion>
          </BoxContent>
        </>
      )}
    </>
  );
};

export default HomeColog;
