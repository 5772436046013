import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Flex,
  Box,
  HStack,
  Tooltip,
  useToast,
  FormControl,
  Center,
  FormErrorMessage,
} from '@chakra-ui/react';
import FormInput from 'components/form/FormInput';
import Button from 'components/Button';
import { FiTrash2 } from 'react-icons/fi';
import FormGroup from 'components/form/FormGroup';
import TituloPagina from 'components/TituloPagina';
import { FaPlus } from 'react-icons/fa';
import { useForm, Controller } from 'react-hook-form';
import { format } from 'date-fns';
import debounce from 'debounce-promise';
import Row from 'components/form/Row';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from '../../components/form/Select';
import AsyncSelect from '../../components/form/AsyncSelect';
import { Table } from './styles';
import api from '../../services/api';
import Accordion from '../../components/Accordion';
import { useAuth } from '../../contexts/auth';
import BoxContent from '../../components/BoxContent';
import DatePicker from '../../components/form/FormDatePicker';

type OptionType = { label: string; value: string };
interface IVetor {
  id: number;
  nome: string;
  tipo: string;
  sexo: string;
  tamanho: string;
  lote: string;
  quantidade: number;
}

interface IEntrada {
  data_entrada: Date;
  data_pedido: Date;
  nota_fiscal: string;
  fornecedor: string | undefined;
}

const schema = Yup.object().shape({
  data_entrada: Yup.date().required('Campo Obrigatório'),
  data_pedido: Yup.date().required('Campo Obrigatório'),
  nota_fiscal: Yup.string().required('Campo Obrigatório'),
  fornecedor: Yup.string().required('Campo Obrigatório'),
});

const CadastrarEstoque: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  /**
   * useform retorna o que esta entre chaves
   */
  const {
    errors,
    control,
    handleSubmit,
    register,
    watch,
    reset,
  } = useForm<IEntrada>({
    resolver: yupResolver(schema),
    defaultValues: {
      data_entrada: undefined,
      data_pedido: undefined,
      nota_fiscal: undefined,
      fornecedor: undefined,
    },
  });
  const history = useHistory();

  const [pecaSelecionada, setPecaSelecionada] = useState<
    OptionType | undefined
  >(undefined);

  const [lote, setLote] = useState<string>('');
  const [erroItem, setErroItem] = useState(false);
  const [quantidade, setQuantidade] = useState<string>('');
  const [itensCarrinho, setItensCarrinho] = useState<IVetor[]>([]);
  const [fornecedoresDisponiveis, setFornecedoresDisponiveis] = useState<any>(
    [],
  );

  const handleAdicionarItem = async (): Promise<void> => {
    if (pecaSelecionada && lote && quantidade) {
      setErroItem(false);
      try {
        const response = await api.get(`itens/${pecaSelecionada.value}`);
        const item: any = response.data;
        setItensCarrinho([
          ...itensCarrinho,
          {
            id: Number(pecaSelecionada.value),
            lote,
            quantidade: Number(quantidade),
            nome: item.nome,
            sexo: item.sexo,
            tamanho: item.tamanho,
            tipo: item.tipo_batalhao,
          },
        ]);
        setPecaSelecionada(undefined);
        setLote('');
        setQuantidade('');
      } catch (error) {
        console.log(error);
      }
    } else {
      setErroItem(true);
    }
  };

  const handleDeletarItem = (id: number): any => {
    const listItemRemovido = itensCarrinho.filter((item) => item.id !== id);

    setItensCarrinho(listItemRemovido);
  };

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }
    }
    load();
    const fornecedores = async (): Promise<any> => {
      const listaFornecedores = await api.get('/fornecedores');
      const optionsFornecedores = listaFornecedores.data.items.map(
        (fornecedor: any) => {
          return {
            value: fornecedor.id_fornecedor,
            label: fornecedor.nome,
          };
        },
      );
      setFornecedoresDisponiveis(optionsFornecedores);
    };

    fornecedores();
  }, [user]);

  const promiseItens = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`itens/pesquisa?query=${inputValue}`);

      const data = response.data || [];

      const responseFormated = data.map((item: any) => {
        return {
          value: item.id_item,
          label: item.pesquisa,
        };
      });
      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

  const delayedQuery = useCallback(
    debounce((query: string) => promiseItens(query), 500),
    [promiseItens],
  );

  const handleSair = (): void => {
    history.push('/listarestoque');
  };

  const handleAdicionarEntrada = async (data: any): Promise<any> => {
    try {
      if (itensCarrinho.length > 0) {
        const dados = {
          id_fornecedor: data.fornecedor,
          nota_fiscal: data.nota_fiscal,
          data_entrada: data.data_entrada,
          data_pedido: data.data_pedido,
          cadastrado_por: user.matricula,
          itens: itensCarrinho.map((obj) => {
            return {
              id_item: obj.id,
              lote: obj.lote.trim(),
              quantidade: obj.quantidade,
            };
          }),
        };
        const inserirEstoque = await api.post(`/estoquecolog`, dados);

        toast({
          title: 'Sucesso.',
          description: 'Cadastro de estoque inserido com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        history.push('/listarestoque');
      } else {
        toast({
          title: 'Atenção',
          description: 'Usuário não informou nenhum item de fardamento',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: 'Erro',
        description: 'Erro ao inserir o estoque',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <>
      <TituloPagina title="Entrada de Estoque Colog" />
      <BoxContent>
        <form onSubmit={handleSubmit(handleAdicionarEntrada)}>
          <Accordion label="Dados da Entrada de Estoque" defaultIndex={[0]}>
            <Flex
              direction="row"
              justifyContent="space-evenly"
              marginTop="1rem"
            >
              <FormGroup name="Fornecedor" cols={[3, 3, 3]}>
                <Select
                  placeholder="Selecione uma Opção"
                  name="fornecedor"
                  ref={register}
                  options={fornecedoresDisponiveis}
                  error={errors.fornecedor}
                />
              </FormGroup>

              <FormGroup name="Data do Pedido" cols={[3, 3, 3]}>
                <Controller
                  name="data_pedido"
                  control={control}
                  render={({ onChange, value }) => (
                    <DatePicker
                      showYearDropdown
                      error={errors.data_pedido?.message}
                      selected={value}
                      onChange={onChange}
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.fornecedor?.message}
                </FormErrorMessage>
              </FormGroup>

              <FormGroup name="Data da Entrada" cols={[3, 3, 3]}>
                <Controller
                  name="data_entrada"
                  control={control}
                  render={({ onChange, value }) => (
                    <DatePicker
                      showYearDropdown
                      selected={value}
                      onChange={onChange}
                      error={errors.data_entrada?.message}
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </FormGroup>

              <FormGroup name="Nota Fiscal nº" cols={[3, 3, 3]}>
                <Controller
                  name="nota_fiscal"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormInput
                      value={value}
                      onChange={onChange}
                      error={errors.nota_fiscal?.message}
                    />
                  )}
                />
              </FormGroup>
            </Flex>
          </Accordion>

          <Accordion label="Dados do Item" mt="2" defaultIndex={[0]}>
            <Button
              colorScheme="green"
              rightIcon={<FaPlus />}
              size="sm"
              onClick={handleAdicionarItem}
            >
              Adicionar Item
            </Button>
            <Flex direction="row" marginTop="1rem" w="100%" flexWrap="wrap">
              <FormGroup name="Pesquisar Item" cols={[8, 12, 12]}>
                <AsyncSelect
                  name="pes_codigo"
                  label="Pesquisar Policial"
                  id="pes_codigo"
                  value={pecaSelecionada}
                  loadOptions={(value: any) => delayedQuery(value)}
                  onChange={(value: any) => setPecaSelecionada(value)}
                  error={erroItem ? 'Campo obrigatório' : undefined}
                />
              </FormGroup>

              <FormGroup name="Lote" cols={[2, 6, 12]}>
                <FormInput
                  id="lote"
                  value={lote}
                  onChange={(e) => setLote(e.target.value)}
                  error={erroItem ? 'Campo obrigatório' : undefined}
                />
              </FormGroup>
              <FormGroup name="Quantidade" cols={[2, 6, 12]}>
                <FormInput
                  id="quantidade"
                  value={quantidade}
                  error={erroItem ? 'Campo obrigatório' : undefined}
                  onChange={(e) => setQuantidade(e.target.value)}
                />
              </FormGroup>
            </Flex>
          </Accordion>
          <div
            style={{
              marginTop: '2rem',
              borderTop: '1px solid grey',
              paddingTop: '1rem',
            }}
          >
            <TituloPagina title="Resumo da Entrada" />
            <Box
              overflowX="auto"
              overflowY="hidden"
              whiteSpace="nowrap"
              maxWidth="100%"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                  height: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                  height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#ccc',
                  borderRadius: '24px',
                },
              }}
            >
              <Box
                maxWidth={{ sm: '280px', md: '100%', lg: '100%', xl: '100%' }}
              >
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Peça</th>
                      <th>Tipo</th>
                      <th>Sexo</th>
                      <th>Tamanho</th>
                      <th>Lote</th>
                      <th>Quantidade</th>

                      <th className="actions" style={{ textAlign: 'center' }}>
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {itensCarrinho &&
                      itensCarrinho.map((peca, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{peca.nome}</td>
                          <td>{peca.tipo}</td>
                          <td>{peca.sexo}</td>
                          <td>{peca.tamanho}</td>
                          <td>{peca.lote}</td>
                          <td>{peca.quantidade}</td>
                          <td className="actions">
                            <HStack>
                              <Tooltip
                                label="Deletar"
                                hasArrow
                                placement="left"
                              >
                                <button type="button">
                                  <FiTrash2
                                    size={20}
                                    color="red"
                                    onClick={() => handleDeletarItem(peca.id)}
                                  />
                                </button>
                              </Tooltip>
                            </HStack>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Box>
            </Box>
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            <Flex direction="row" justifyContent="space-around" marginTop={10}>
              <Button onClick={handleSair} colorScheme="red">
                Cancelar
              </Button>
              <Button colorScheme="green" type="submit">
                Inserir Estoque
              </Button>
            </Flex>
          </div>
        </form>
      </BoxContent>
    </>
  );
};
export default CadastrarEstoque;
