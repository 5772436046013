/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaSave, FaSearch, FaTimes } from 'react-icons/fa';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { format, compareAsc, parseISO } from 'date-fns'

import {
  Center,
  Button,
  Flex,
  Table as TableChakra,
  Thead,
  Tbody,
  Tfoot,
  Tooltip,
  HStack,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  TableCaption,
  useToast,
  FormErrorMessage,
  FormControl,
  Box,
  useDisclosure,
  Modal,
  Container,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { FaPlus } from 'react-icons/fa';
import FormGroup from 'components/form/FormGroup';
import FormInput from 'components/form/FormInput';
import { FiTrash2 } from 'react-icons/fi';
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'debounce-promise';
import { useForm } from 'react-hook-form';
import { ValueType } from 'react-select';
import { convertCompilerOptionsFromJson } from 'typescript';
import { BeatLoader } from 'react-spinners';
import { Select } from '../../components/form/Select';
import { formatDate } from '../../utils/formataData'
import { Table, Content } from './styles';
import AsyncSelect from '../../components/form/AsyncSelect';
import Accordion from '../../components/Accordion';
import api from '../../services/api';
import { Select as SelectMod } from '../../components/form/SelectLote';
import BoxContent from '../../components/BoxContent';
import PanelBottomActions from '../../components/PanelBottomActions';
import ModelSav from '../../components/Modal_Sav';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useSaida } from '../../contexts/saida'
import DataTable, { IColumns } from '../../components/DataTable';



type OptionType = { label: string; value: string | number; opm: any };

interface IVetor {
  id: number;
  nome: string;
  tipo: string;
  sexo: string;
  lote: string;
  tamanho: string;
  quantidade: number;
  label: string;
}

interface IPolicialRecebedor {
  matricula: string;
  label: string;
}

interface IOpmRecebedor {
  uni_codigo: number;
  uni_sigla: string;
}



const PagarFardamentoPm: React.FC = () => {
  const { user } = useAuth();
  const [selectItens, setSelectItens] = useState<OptionType[] | undefined>(
    undefined,  );
  const history = useHistory();
  const toast = useToast();
  const [pecaSelecionada, setPecaSelecionada] = useState<any | undefined>(
    undefined,
  );
  
  const [lote, setLote] = useState<string>('');  
  const [erroItem, setErroItem] = useState(false);
  const [quantidade, setQuantidade] = useState<string>('');
  const [estoque, setEstoque] = useState<number | string | undefined>();  
  const [itensCarrinho, setItensCarrinho] = useState<IVetor[]>([]);
  const [botao, setBotao] = useState(true);
  const { idSaida } = useSaida()

  const {
    isOpen: isOpenConfirmacaoMovimentacao,
    onClose: onCloseConfirmacaoMovimentacao,
    onOpen: onOpenConfirmacaoMovimentacao,
  } = useDisclosure();

  const handleAdicionarItem = async (): Promise<void> => {

    if (pecaSelecionada && quantidade && lote && (Number(quantidade) > 0)) {
      setErroItem(false);

      if ((Number(estoque) - Number(quantidade)) < 0) {
        toast({
          title: 'Erro.',
          description: 'Estoque insuficiente',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        try {
          const response = await api.get(`itens/${pecaSelecionada.value}`);
          const item: any = response.data;
          setItensCarrinho([
            ...itensCarrinho,
            {
              id: Number(pecaSelecionada.value),
              label: pecaSelecionada.label,
              quantidade: Number(quantidade),
              nome: item.nome,
              sexo: item.sexo,
              lote,
              tamanho: item.tamanho,
              tipo: item.tipo_batalhao,
            },
          ]);
          setPecaSelecionada(0);
          setLote('')
          setQuantidade('');
          setEstoque(undefined);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      setErroItem(true);
    }
  };

 

  const handleDeletarItem = (id: any): any => {

    const listItemRemovido = itensCarrinho.filter((item) => item.id !== id);

    setItensCarrinho(listItemRemovido);
    setPecaSelecionada(0);
    setQuantidade('');
    setLote('')
    setEstoque(undefined);

  };

  const [opmSelecionada, setOpmSelecionada] = useState<IOpmRecebedor>();
  const [policialSelecionado, setPolicialSelecionado] = useState<IPolicialRecebedor>();
  const [lotesDisponiveis, setLotesDisponiveis] = useState<any>(
    [],
  );  


  const colunas: IColumns = [
    {
      field: 'data_saida',
      text: 'Data',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
    },
    {
      field: 'opm_sigla',
      text: 'Unidade',
      type: {
        name: 'text',
      },
    },
    {
      field: 'despachado_por',
      text: 'Pago por',
      type: {
        name: 'text',
      },
    },
    {
      field: 'recebido_por',
      text: 'Recebido por',
      type: {
        name: 'text',
      },
    },

    {
      field: 'peca',
      text: 'Item de Fardamento',
      type: {
        name: 'text',
      },
    },
    {
      field: 'quantidade',
      text: 'Quantidade Paga',
      type: {
        name: 'text',
      },
    }, {
      field: 'status',
      text: 'Status',
      type: {
        name: 'enum',
        enum: {
          '1': 'Pendente de Assinatura',
          '2': 'Liberado',
        },
      },
    },
  ];

  const options = {
    serverData: {
      url: `/itenssaidascolog/historicopagamentoopm`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `opm=${opmSelecionada?.uni_codigo}`,
    },

    filters: [
      // maxWidth="calc(100vw - 40px)"
    ],
    search: {
      searchable: true,
      label: 'Pesquisar/Filtrar',
      fields: ['nota_fiscal'],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };


  const handleConfirmarSaida = async (data: any): Promise<any> => {
    setBotao(false)
    const policialPagador = {
      matricula: user.matricula,
      graduacao: user.graduacao?.gra_sigla,
      nome: user.nome,
      cpf: user.cpf,
      opm: user.opm
    }
    // Inserir Saida
    
    try {
      if (itensCarrinho.length > 0) {
        
        const dados = {
          id_saida: idSaida,
          opmRecebedora: opmSelecionada?.uni_codigo,
          policialPagador,
          matriculaRecebedor: policialSelecionado?.matricula,
          opmRecebedor: opmSelecionada?.uni_codigo,
          data_saida: format(new Date(), 'yyyy/MM/dd'),
          status: '1',
          itens: itensCarrinho.map((obj) => {

            return {
              id_item: obj.id,
              label: obj.label,
              quantidade: obj.quantidade,
              lote: obj.lote
            };
          }),
        };
        

         const inserirSaidaEstoque = await api.put(`/saidacolog/pagamentocologopm`, dados);

        toast({
          title: 'Sucesso.',
          description: 'Pagamento editado com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
         history.push('/documentospendentescolog');
      } else {
        toast({
          title: 'Atenção',
          description: 'Usuário não informou nenhum item de fardamento',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setBotao(true)
    }


    // Gerar Documento

  }

  const handleAdicionarSaida = async (data: any): Promise<any> => {
    onOpenConfirmacaoMovimentacao();
  };


  const promiseItens = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`itens/pesquisa?query=${inputValue}`);

      const data = response.data || [];

      const responseFormated = data.map((item: any) => {
        return {
          value: item.id_item,
          label: item.pesquisa,
        };
      });
      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

 
  const delayedQueryItens = useCallback(
    debounce((query: string) => promiseItens(query), 500),
    [promiseItens],
  );

  const carregaEstoque = async (dados: any): Promise<any> => {

    try {
      const estoqueDados = await api.get(`itens/estoque/${dados.value}`);
      const estoqueAtual = estoqueDados.data.quantidade - itensCarrinho.filter(objeto => objeto.id === Number(dados.value)).reduce((valorAtual, objeto2) => {
        return valorAtual + Number(objeto2.quantidade);
      }, 0)
      if (estoqueAtual > 0) {
        setEstoque(estoqueDados.data.quantidade - itensCarrinho.filter(objeto => objeto.id === Number(dados.value)).reduce((valorAtual, objeto2) => {
          return valorAtual + Number(objeto2.quantidade);
        }, 0));
      } else {
        setEstoque('0');
      }
    } catch (error) {
      setEstoque('0');
    }
  };


  useEffect(() => {

    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }     

    }
    load();
  }, [user]);

  useEffect(() => {
    /**
     * Pego o id da saida vindo do datatable da pagina anterior que
     * é setado via context posteriormente crio um objeto nos moldes
     * do vetor de itens do carrinho que será inicialmente carregado com
     * os dados daquela saida
     */

    async function load(): Promise<void> {
      
      const dadosSaida = await api.get(
        `saidacolog/mostrarsaida/${idSaida}`,
        );
        
        
        
        
        const policialDados = await api.get(
          `policiais/policialfull/${dadosSaida.data.cod_pm}`,
        );
        
        setOpmSelecionada({
          uni_codigo: policialDados.data.opm.uni_codigo,
          uni_sigla:policialDados.data.opm.uni_sigla,
        })  
        setPolicialSelecionado({
          matricula: policialDados.data.pm_codigo,
          label: `MF. ${policialDados.data.pm_codigo}, Nº ${policialDados.data.pm_numero || ''}, ${policialDados.data.graduacao.gra_sigla}. ${policialDados.data.pessoa.pes_nome}`
        })
      
      
      const carrinhoEdit = dadosSaida.data.itens
      
      const carrinhoLoop = carrinhoEdit.map((obj:any):any => {
        const categoria = obj.item.id_categoria ===1 ? 'Fardamento' : 'Acessorio'
        return {
          id: obj.id_item,
          label: `Categoria: ${categoria} - Item: ${obj.item.nome} - Sexo: ${obj.item.sexo} - Tamanho: ${obj.item.tamanho} - Lateralidade: ${obj.item.lateralidade_corporal} - Modalidade: ${obj.item.modalidade_coturno} - Tipo: ${obj.item.tipo_batalhao} `,
          quantidade: obj.quantidade,
          nome: obj.item.nome,
          sexo: obj.item.sexo,
          lote: obj.lote,
          tamanho: obj.item.tamanho,
          tipo: obj.item.tipo_batalhao
        }
      })
      
      setItensCarrinho(carrinhoLoop);
      // setOpmSelecionada(dadosSaida.data.opm)
      // setPolicialSelecionado(dadosSaida.data.cod_pm)
      const selectItensDados = await api.get(
        `itens`,
      );
      if (selectItensDados.data) {

        const optionsItens = selectItensDados.data.items.map((item: any) => {
          return {
            value: item.id_item,
            label: `Nome: ${item.nome}: tamanho: ${item.tamanho || 'Único'
              } tipo: ${item.tipo_batalhao || 'Único'} `,
          };
        });
        setSelectItens(optionsItens);

      }
    }
    load();
  }, []);


  useEffect(() => {
    const lotes = async (): Promise<any> => {
      const listaLotes = await api.get('/entradascolog/lotes');


      const optionsLotes = listaLotes.data.map(
        (listarlote: any) => {
          return {
            value: listarlote.lote,
            label: listarlote.lote,

          };

        },
      );
      setLotesDisponiveis(optionsLotes);
    };

    lotes();

  }, [user]);


  const handleSair = (): void => {
    history.push('/documentospendentescolog');
  };

  const handleBotaoConfirmar = (): any => {
    if (botao) {
      return (
        <Button
          ml="8px"
          type="button"
          size="sm"
          colorScheme="green"
          onClick={handleConfirmarSaida}
        >
          Confirmar
        </Button>
      )
    }
    return (
      <Button
        isLoading
        spinner={<BeatLoader size={8} color='white' />}
        ml="8px"
        loadingText='Enviando'
        type="button"
        size="sm"
        colorScheme="green"
      >
        Confirmar
      </Button>

    )
  }

  return (
    <>
      <>
        <TituloPagina
          title={`
            Pagar Fardamento OPM

            `}
        />
        {opmSelecionada && policialSelecionado && (
        <BoxContent>
          <Box maxWidth="calc(100vw - 50px)">
            Unidade
            <Flex width="50%">
              <FormGroup name="" cols={[7, 7, 12]}>
                <FormInput
                  id="opm"
                  value={opmSelecionada?.uni_sigla}
                  disabled
                  error={erroItem ? 'Campo obrigatório' : undefined}
                  onChange={(e) => setQuantidade(e.target.value)}
                />
              </FormGroup>
            </Flex>
          </Box>
          <Box maxWidth="calc(100vw - 50px)">
            Buscar Policial Recebedor
            <Flex width="50%">
              <FormGroup name="" cols={[7, 7, 12]}>
                <FormInput
                  id="policial"
                  value={policialSelecionado?.label}
                  disabled
                  error={erroItem ? 'Campo obrigatório' : undefined}
                  onChange={(e) => setQuantidade(e.target.value)}
                />
              </FormGroup>
            </Flex>
          </Box>
          
          <>
            <Box maxWidth="calc(100vw - 50px)" mt="2">

              <Accordion label="Selecionar Itens para Pagamento" mt="2" defaultIndex={[0]} minWidth="700px">

                <Flex direction="row" marginTop="1rem" w="100%" flexWrap="wrap">
                  <FormGroup name="Pesquisar Item" cols={[7, 10, 10]}>
                    <AsyncSelect
                      name="pes_codigo"
                      label="Pesquisar Policial"
                      id="pes_codigo"
                      value={pecaSelecionada}
                      loadOptions={(value: any) => delayedQueryItens(value)}
                      onChange={(value: any) => {
                          setPecaSelecionada(value)
                          carregaEstoque(value)
                        }}
                      error={erroItem ? 'Campo obrigatório' : undefined}
                    />
                  </FormGroup>

                  <FormGroup name="Lote" cols={[1, 1, 1]}>
                    <SelectMod
                      placeholder="Selecione uma Opção"
                      name="lote"
                      value={lote}
                      options={lotesDisponiveis}
                      onChange={(e) => setLote(e.target.value)}
                      error={erroItem ? 'Campo obrigatório' : undefined}
                    />
                  </FormGroup>

                  <FormGroup name="Quantidade" cols={[2, 6, 12]}>
                    <FormInput
                      id="quantidade"
                      value={quantidade}
                      error={erroItem ? 'Campo obrigatório' : undefined}
                      onChange={(e) => setQuantidade(e.target.value)}
                    />
                  </FormGroup>
                  {estoque && (
                    <FormGroup name="Estoque" cols={[1, 5, 11]}>
                      <FormInput disabled value={estoque} />
                    </FormGroup>
                    )}
                  <FormGroup name="ㅤ" cols={[1, 5, 11]}>
                    <Button
                      colorScheme="green"
                      rightIcon={<FaPlus />}
                      size="sm"
                      onClick={handleAdicionarItem}
                    >
                      Adicionar Item
                    </Button>
                  </FormGroup>
                </Flex> 
                <div
                  style={{
                      marginTop: '2rem',
                      borderTop: '1px solid grey',
                      paddingTop: '1rem',
                    }}
                >
                  <TituloPagina title="Resumo da Entrada" />
                  <Box
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                    maxWidth="100%"
                    css={{
                        '&::-webkit-scrollbar': {
                          width: '4px',
                          height: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                          width: '6px',
                          height: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#ccc',
                          borderRadius: '24px',
                        },
                      }}
                  >
                    <Box
                      maxWidth={{
                          sm: '280px',
                          md: '100%',
                          lg: '100%',
                          xl: '100%',
                        }}
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Peça</th>
                            <th>Tipo</th>
                            <th>Sexo</th>
                            <th>Lote</th>
                            <th>Tamanho</th>
                            <th>Quantidade</th>

                            <th
                              className="actions"
                              style={{ textAlign: 'center' }}
                            >
                              Ações
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {itensCarrinho &&
                              itensCarrinho.map((peca, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{peca.nome}</td>
                                  <td>{peca.tipo || 'Único'}</td>
                                  <td>{peca.sexo || 'Único'}</td>
                                  <td>{peca.lote}</td>
                                  <td>{peca.tamanho || 'Único'}</td>
                                  <td>{peca.quantidade}</td>
                                  <td className="actions">
                                    <HStack>
                                      <Tooltip
                                        label="Deletar"
                                        hasArrow
                                        placement="left"
                                      >
                                        <button type="button">
                                          <FiTrash2
                                            size={20}
                                            color="red"
                                            onClick={() =>
                                              handleDeletarItem(peca.id)
                                            }
                                          />
                                        </button>
                                      </Tooltip>
                                    </HStack>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </Table>
                    </Box>
                  </Box>
                </div>
              </Accordion>
              <Accordion label="Histórico de Recebimentos da OPM" mt="2" minWidth="700px">
                <BoxContent>
                  <Box maxWidth="calc(100vw - 50px)">
                    <DataTable columns={colunas} options={options} />
                  </Box>
                </BoxContent>
              </Accordion> 

              <Flex mt="16px" direction="row" justifyContent="center">
                <Button onClick={handleSair} size="sm" colorScheme="red">
                  Sair
                </Button>

                <Button ml="8px" type="submit" size="sm" colorScheme="green" onClick={handleAdicionarSaida}>
                  Reservar
                </Button>
              </Flex>
            </Box>
            <ModelSav
              onClose={onCloseConfirmacaoMovimentacao}
              isOpen={isOpenConfirmacaoMovimentacao}
              size="xl"
              title="Itens do Pagamento"
            >
              <Container>
                <Content style={{ textAlign: 'center', fontSize: '1.3rem' }}>
                  <b>
                    Você deseja confirmar o pagamento para a unidade {opmSelecionada.uni_sigla} dos seguintes itens:

                    <Table>
                      <thead>
                        <tr>
                          <th className="tableCarrinhoModal">#</th>
                          <th className="tableCarrinhoModal">Peça</th>
                          <th className="tableCarrinhoModal">Tipo</th>
                          <th className="tableCarrinhoModal">Sexo</th>
                          <th className="tableCarrinhoModal">Lote</th>
                          <th className="tableCarrinhoModal">Tamanho</th>
                          <th className="tableCarrinhoModal">Quantidade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itensCarrinho &&
                            itensCarrinho.map((peca, index) => (
                              <tr key={index}>
                                <td className="tableCarrinhoModal">{index + 1}</td>
                                <td className="tableCarrinhoModal">{peca.nome}</td>
                                <td className="tableCarrinhoModal">{peca.tipo || 'Único'}</td>
                                <td className="tableCarrinhoModal">{peca.sexo || 'Único'}</td>
                                <td className="tableCarrinhoModal">{peca.lote}</td>
                                <td className="tableCarrinhoModal">{peca.tamanho || 'Único'}</td>
                                <td className="tableCarrinhoModal">{peca.quantidade}</td>
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                  </b>
                </Content>                
                <PanelBottomActions>
                  <Button onClick={onCloseConfirmacaoMovimentacao} size="sm" colorScheme="red">
                    Sair
                  </Button>
                  {handleBotaoConfirmar()}
                </PanelBottomActions>
              </Container>
            </ModelSav>
          </>         
        </BoxContent>
      )}
      </>
    </>
  );
};

export default PagarFardamentoPm;
