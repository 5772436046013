import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormGroup from 'components/form/FormGroup';
import {
  VStack,
  SimpleGrid,
  Box,
  useToast,
  Stack,
  HStack,
  Flex,
  Button,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/form/FormInput';
import BoxContent from '../../components/BoxContent';

import api from '../../services/api';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useFornecedor } from '../../contexts/fornecedor';

interface IEntrada {
  nome: string;
  endereco: string;
  telefone: string;
  cnpj: string;
}

const schema = Yup.object().shape({
  nome: Yup.string().required('Campo Obrigatório'),
  endereco: Yup.string().required('Campo Obrigatório'),
  telefone: Yup.string().required('Campo Obrigatório'),
  cnpj: Yup.string().required('Campo Obrigatório'),
});

const EditarFornecedor: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  const [fornecedor, setFornecedor] = useState<IEntrada>();
  const { idFornecedor } = useFornecedor();
  const history = useHistory();
  const { errors, handleSubmit, register } = useForm<IEntrada>({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: fornecedor?.nome,
      endereco: fornecedor?.endereco,
      telefone: fornecedor?.telefone,
      cnpj: fornecedor?.cnpj,
    },
  });

  const handleAdicionarFornecedor = async (data: any): Promise<any> => {
    const dados = {
      ...data,
      atualizado_por: user.matricula,
    };
    try {
      const EditarFornecedo = await api.put(
        `/fornecedores/${idFornecedor}`,
        dados,
      );
      toast({
        title: 'Sucesso.',
        description: 'Fornecedor atualizado com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listarfornecedores');
    } catch (error) {
      toast({
        title: 'Erro',
        description:
          error.response.data.message || 'Erro ao atualizar o fornecedor',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (user.currentPerfil === 'SISFARD - OPM') {
        history.push('/home');
      }
      if (idFornecedor) {
        const fornecedordata = await api.get(`/fornecedores/${idFornecedor}`);
        setFornecedor(fornecedordata.data);
      }
    }
    load();
  }, [user]);

  const handleSair = (): void => {
    history.push('/listarfornecedores');
  };

  return (
    <>
      {fornecedor && (
        <Stack>
          <TituloPagina title="Edição de Fornecedor" />
          <BoxContent>
            <Box
              as="form"
              flex="1"
              onSubmit={handleSubmit(handleAdicionarFornecedor)}
            >
              <VStack spacing="4">
                <SimpleGrid minChildWidth="300px" spacing={['2', '4']} w="100%">
                  <FormGroup name="Nome Fornecedor">
                    <FormInput
                      name="nome"
                      defaultValue={fornecedor.nome}
                      register={register}
                      error={errors.nome?.message}
                    />
                  </FormGroup>
                  <FormGroup name="Endereço">
                    <FormInput
                      name="endereco"
                      defaultValue={fornecedor.endereco}
                      register={register}
                      error={errors.endereco?.message}
                    />
                  </FormGroup>
                  <FormGroup name="Telefone">
                    <FormInput
                      name="telefone"
                      defaultValue={fornecedor.telefone}
                      register={register}
                      error={errors.telefone?.message}
                    />
                  </FormGroup>
                  <FormGroup name="CNPJ">
                    <FormInput
                      name="cnpj"
                      defaultValue={fornecedor.cnpj}
                      register={register}
                      error={errors.cnpj?.message}
                    />
                  </FormGroup>
                </SimpleGrid>
                <Flex mt="8" justify="flex-end">
                  <HStack spacing="4">
                    <Button onClick={handleSair} colorScheme="red">
                      Voltar
                    </Button>

                    <Button colorScheme="green" type="submit">
                      Salvar
                    </Button>
                  </HStack>
                </Flex>
              </VStack>
            </Box>
          </BoxContent>
        </Stack>
      )}
    </>
  );
};

export default EditarFornecedor;
