/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';

import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Center,
  Button,
  Flex,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Select,
  Tr,
  Th,
  Td,
  TableCaption,
  useToast,
  FormErrorMessage,
  FormControl,
  Box,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Accordion from '../../components/Accordion';

import api from '../../services/api';

import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useFardamento } from '../../contexts/fardamento';

type IdFardamento = {
  id: string;
};

interface IFardamento {
  peu_codigo: string;
  pm_apelido: string;
  gra_codigo: string;
  peu_cabeca: string;
  peu_blusa_interna: string;
  peu_calca: string;
  data_alteracao: Date;
  peu_gandola: string;
  pm_codigo: string;
  peu_calca_sexo: string;
  peu_gandola_sexo: string;
  peu_coldre: string;
  peu_sapato: string;
  peu_combatshirt: string;  
}

/**
 * Ajeitar essa logica na hora de subir para produção, nao quero fazer join por ser poucos dados
 * pensei na hora da autenticação trazer a tabela toda uma vez que seria apenas um efeito visual
 * pois o codigo da graduação do pm esta vindo de uma consulta
 */

const graduacoes = [
  { gra_codigo: 7, gra_nome: 'ASPIRANTE-A-OFICIAL', gra_sigla: 'ASP' },
  { gra_codigo: 15, gra_nome: 'ALUNO CFS', gra_sigla: 'AL CFS' },
  { gra_codigo: 17, gra_nome: 'ALUNO CHC', gra_sigla: 'AL CHC' },
  { gra_codigo: 19, gra_nome: 'ALUNO CFSDF', gra_sigla: 'AL CFSDF' },
  { gra_codigo: 20, gra_nome: 'FUNCIONARIO CIVIL', gra_sigla: 'FUNC. CIVIL' },
  { gra_codigo: 21, gra_nome: 'SOLDADO TEMPORARIO', gra_sigla: 'SD PM TEMP' },
  { gra_codigo: 8, gra_nome: 'CFO - 3º ANO', gra_sigla: 'CFO-3ºANO' },
  { gra_codigo: 9, gra_nome: 'CFO - 2º ANO', gra_sigla: 'CFO-2ºANO' },
  { gra_codigo: 10, gra_nome: 'CFO - 1º ANO', gra_sigla: 'CFO-1ºANO' },
  { gra_codigo: 18, gra_nome: 'SOLDADO', gra_sigla: 'SD' },
  { gra_codigo: 16, gra_nome: 'CABO', gra_sigla: 'CB' },
  { gra_codigo: 14, gra_nome: '3º SARGENTO', gra_sigla: '3ºSGT' },
  { gra_codigo: 13, gra_nome: '2º SARGENTO', gra_sigla: '2ºSGT' },
  { gra_codigo: 12, gra_nome: '1º SARGENTO', gra_sigla: '1ºSGT' },
  { gra_codigo: 11, gra_nome: 'SUBTENENTE', gra_sigla: 'SUBTEN' },
  { gra_codigo: 6, gra_nome: '2º TENENTE', gra_sigla: '2ºTEN' },
  { gra_codigo: 5, gra_nome: '1º TENENTE', gra_sigla: '1ºTEN' },
  { gra_codigo: 4, gra_nome: 'CAPITÃO', gra_sigla: 'CAP' },
  { gra_codigo: 3, gra_nome: 'MAJOR', gra_sigla: 'MAJ' },
  { gra_codigo: 2, gra_nome: 'TENENTE-CORONEL', gra_sigla: 'TEN-CEL' },
  { gra_codigo: 1, gra_nome: 'CORONEL', gra_sigla: 'CEL' },
  {
    gra_codigo: 0,
    gra_nome: 'CORONEL COMANDANTE-GERAL',
    gra_sigla: 'CEL CMTE-GERAL',
  },
];

const schema = Yup.object().shape({
  /*  peu_cabeca: Yup.string()
    .required('PIPI MOLE')
    .transform((value: string) =>
      value.trim().length === 0 ? undefined : value,
    ), */
  peu_calca: Yup.string().required('Campo Obrigatório'),
  peu_calca_sexo: Yup.string().required('Campo Obrigatório'),
  peu_cabeca: Yup.string().required('Campo Obrigatório'),
  // peu_gandola_sexo: Yup.string().required('Campo Obrigatório'),
  peu_blusa_interna: Yup.string().required('Campo Obrigatório'),
  // peu_gandola: Yup.string().required('Campo Obrigatório'),
  peu_sapato: Yup.string().required('Campo Obrigatório'),
  peu_coldre: Yup.string().required('Campo Obrigatório'),
  peu_combatshirt: Yup.string().required('Campo Obrigatório'),
 
  // data_alteracao: Yup.date(),
  // pm_codigo: Yup.string().required('PIPI MOLE'),
  // peu_gandola_sexo: Yup.string().required('PIPI MOLE'),
});

const EditarFardamento: React.FC = () => {
  const { user } = useAuth();
  const { idFardamento } = useFardamento();

  const [fardamento, setFardamento] = useState<IFardamento | undefined>(
    undefined,
  );
  const history = useHistory();
  const toast = useToast();
  const {
    control,
    errors,
    reset,
    handleSubmit,
    getValues,
    register,
  } = useForm<IFardamento>({
    resolver: yupResolver(schema),
    defaultValues: {
      peu_cabeca: fardamento?.peu_cabeca,
      peu_calca: fardamento?.peu_calca,
      peu_blusa_interna: fardamento?.peu_blusa_interna,
      peu_calca_sexo: fardamento?.peu_calca_sexo,
      peu_coldre: fardamento?.peu_coldre,
     // peu_gandola_sexo: fardamento?.peu_gandola_sexo,
      peu_sapato: fardamento?.peu_sapato,
     // peu_gandola: fardamento?.peu_gandola,
      peu_combatshirt: fardamento?.peu_combatshirt,
    },
  });

  useEffect(() => {
    async function load(): Promise<void> {
      if (user.currentPerfil === 'SISFARD - BCG') {
        history.push('/edituser');
      }
      if (idFardamento) {
        const uniforme = await api.get(
          `/policiais/uniformes/cod/${idFardamento}`,
        );
        setFardamento(uniforme.data[0]);       
      }
    }
    load();
  }, [idFardamento, user]);

  const handleUpdate = async (data: any): Promise<void> => {
    const atualizar = await api.put(
      `/policiais/uniformes/${idFardamento}`,
      data,
    );
    toast({
      title: 'Sucesso.',
      description: 'Fardamento Atualizado com sucesso',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });
    history.push('/cadastrarpendentes');
  };

  const handleSair = (): void => {
    history.push('/listarfardamentos');
  };

  const handleLimpar = (): void => {
    reset({
      peu_cabeca: '',
      peu_calca: '',
      peu_calca_sexo: '',
      peu_blusa_interna: '',
      peu_gandola: '',
      peu_coldre: '',
      peu_gandola_sexo: '',
      peu_sapato: '',
      peu_combatshirt: '',      
    });
  };

  return (
    <>
      {fardamento && (
        <>
          <TituloPagina
            title={`
            Fardamento ${graduacoes.find(
              (x) => x.gra_codigo === Number(fardamento.gra_codigo),
            )?.gra_nome
              } ${fardamento.pm_apelido} - M.F:${fardamento.pm_codigo}
            `}
          />
          <BoxContent>
            <Box maxWidth="calc(100vw - 50px)">
              <form onSubmit={handleSubmit(handleUpdate)}>
                <Accordion
                  defaultIndex={[0]}
                  label="Fardamento"
                  mt="2"
                  minWidth="700px"
                >
                  <Table variant="simple">
                    <TableCaption>
                      Formulário de edição de fardamento
                    </TableCaption>
                    <Thead>
                      <Tr>
                        <Th> #</Th>
                        <Th>Peça</Th>
                        <Th>Tipo</Th>
                        <Th>Tamanho</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>1</Td>
                        <Td>Cobertura</Td>
                        <Td>------</Td>
                        <Td>
                          <FormControl
                            id="peu_cabeca"
                            isInvalid={!!errors.peu_cabeca}
                          >
                            <Select
                              placeholder="Selecione"
                              ref={register}
                              defaultValue={fardamento.peu_cabeca}
                              name="peu_cabeca"
                            >
                              <option value="55">55</option>
                              <option value="56">56</option>
                              <option value="57">57</option>
                              <option value="58">58</option>
                              <option value="59">59</option>
                              <option value="60">60</option>
                              <option value="61">61</option>
                              <option value="62">62</option>
                              <option value="63">63</option>
                              <option value="64">64</option>
                              <option value="65">65</option>
                              <option value="66">66</option>
                            </Select>
                            <Center>
                              <FormErrorMessage>
                                <strong>{errors.peu_cabeca?.message}</strong>
                              </FormErrorMessage>
                            </Center>
                          </FormControl>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>2</Td>
                        <Td>Camisa Interna</Td>
                        <Td>------</Td>
                        <Td>
                          <FormControl
                            id="peu_blusa_interna"
                            isInvalid={!!errors.peu_blusa_interna}
                          >
                            <Select
                              placeholder="Selecione"
                              ref={register}
                              defaultValue={fardamento.peu_blusa_interna}
                              name="peu_blusa_interna"
                            >
                              <option value="P">P</option>
                              <option value="M">M</option>
                              <option value="G">G</option>
                              <option value="GG">GG</option>
                              <option value="XG">XG</option>
                            </Select>
                            <Center>
                              <FormErrorMessage>
                                <strong>
                                  {errors.peu_blusa_interna?.message}
                                </strong>
                              </FormErrorMessage>
                            </Center>
                          </FormControl>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>3</Td>
                        <Td>Calça</Td>
                        <Td>
                          <FormControl
                            id="peu_calca_sexo"
                            isInvalid={!!errors.peu_calca_sexo}
                          >
                            <Select
                              placeholder="Selecione"
                              ref={register}
                              defaultValue={fardamento.peu_calca_sexo}
                              name="peu_calca_sexo"
                            >
                              <option value="M">Masculino</option>
                              <option value="F">Feminino</option>
                            </Select>
                            <Center>
                              <FormErrorMessage>
                                <strong>
                                  {errors.peu_calca_sexo?.message}
                                </strong>
                              </FormErrorMessage>
                            </Center>
                          </FormControl>
                        </Td>
                        <Td>
                          <FormControl
                            id="peu_calca"
                            isInvalid={!!errors.peu_calca}
                          >
                            <Select
                              placeholder="Selecione"
                              ref={register}
                              defaultValue={fardamento.peu_calca}
                              name="peu_calca"
                            >
                              <option value="36">36</option>
                              <option value="38">38</option>
                              <option value="40">40</option>
                              <option value="42">42</option>
                              <option value="44">44</option>
                              <option value="46">46</option>
                              <option value="48">48</option>
                              <option value="50">50</option>
                              <option value="52">52</option>
                              <option value="54">54</option>
                              <option value="56">56</option>
                              <option value="58">58</option>
                              <option value="60">60</option>
                              <option value="62">62</option>
                            </Select>
                            <Center>
                              <FormErrorMessage>
                                <strong>{errors.peu_calca?.message}</strong>
                              </FormErrorMessage>
                            </Center>
                          </FormControl>
                        </Td>
                      </Tr>
                      
                      <Tr>
                        <Td>4</Td>
                        <Td>Coturno</Td>
                        <Td>------</Td>
                        <Td>
                          <FormControl
                            id="peu_sapato"
                            isInvalid={!!errors.peu_sapato}
                          >
                            <Select
                              placeholder="Selecione"
                              ref={register}
                              defaultValue={fardamento.peu_sapato}
                              name="peu_sapato"
                            >
                              <option value="35">35</option>
                              <option value="36">36</option>
                              <option value="37">37</option>
                              <option value="38">38</option>
                              <option value="39">39</option>
                              <option value="40">40</option>
                              <option value="41">41</option>
                              <option value="42">42</option>
                              <option value="43">43</option>
                              <option value="44">44</option>
                              <option value="45">45</option>
                              <option value="46">46</option>
                              <option value="47">47</option>
                              <option value="48">48</option>
                              <option value="49">49</option>
                              <option value="50">50</option>
                            </Select>
                            <Center>
                              <FormErrorMessage>
                                <strong>{errors.peu_sapato?.message}</strong>
                              </FormErrorMessage>
                            </Center>
                          </FormControl>
                        </Td>
                      </Tr>
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Td>5</Td>
                        <Td>Coldre</Td>
                        <Td>
                          <FormControl
                            id="peu_coldre"
                            isInvalid={!!errors.peu_coldre}
                          >
                            <Select
                              placeholder="Selecione"
                              ref={register}
                              defaultValue={fardamento.peu_coldre}
                              name="peu_coldre"
                            >
                              <option value="D">Destro</option>
                              <option value="C">Canhoto</option>
                            </Select>
                            <Center>
                              <FormErrorMessage>
                                <strong>{errors.peu_coldre?.message}</strong>
                              </FormErrorMessage>
                            </Center>
                          </FormControl>
                        </Td>
                        <Td>---</Td>
                      </Tr>
                      <Tr>
                        <Td>6</Td>
                        <Td>Camisa Tática</Td>
                        <Td>---</Td>
                        <Td>
                          <FormControl
                            id="peu_combatshirt"
                            isInvalid={!!errors.peu_combatshirt}
                          >
                            <Select
                              placeholder="Selecione"
                              ref={register}
                              defaultValue={fardamento.peu_combatshirt}
                              name="peu_combatshirt"
                            >
                              <option value="PP">PP</option>
                              <option value="P">P</option>
                              <option value="M">M</option>
                              <option value="G">G</option>
                              <option value="GG">GG</option>
                              <option value="XG">XG</option>
                              <option value="XXG">XXG</option>
                              <option value="XXXG">XXXG</option>                              
                            </Select>
                            <Center>
                              <FormErrorMessage>
                                <strong>{errors.peu_combatshirt?.message}</strong>
                              </FormErrorMessage>
                            </Center>
                          </FormControl>
                        </Td>
                      </Tr>
                    </Tfoot>
                  </Table>
                </Accordion>
                <Flex
                  mt="16px"
                  mb="16px"
                  direction="row"
                  justifyContent="center"
                  minWidth="700px"
                  width="100%"
                >
                  <Button onClick={handleSair} size="sm" colorScheme="red">
                    Sair
                  </Button>
                  <Button
                    ml="8px"
                    size="sm"
                    colorScheme="yellow"
                    textColor="white"
                    onClick={handleLimpar}
                  >
                    Limpar
                  </Button>
                  <Button ml="8px" type="submit" size="sm" colorScheme="green">
                    Salvar
                  </Button>
                </Flex>
              </form>
            </Box>
          </BoxContent>
        </>
      )}
    </>
  );
};

export default EditarFardamento;